import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Card, Button, Modal, Spin, Space } from 'antd';
import dayjs from 'dayjs';

import {
  setModal,
  setCurrentClient,
  setCurrentHotlineList,
  setCurrentReachList,
  updateInsurance,
  setActivationInsurance,
  editContract,
} from '../../store/commonReducer';
import {
  FORM_OF_BUSINESS,
  HOTLINE_ADV_WHERE_MORE,
  JOBS,
  PAYMENT_FORMAT,
  SERVIS_OPTIONS,
  TOWNS,
  WORK_STATES,
  HOBBIES,
  SOC_STATUS,
  RELATIONS,
  CHARACTERISTIC,
  REPEATS,
  RED_BUTTON_PRICE,
  INCOME_TYPES,
  PASSPORT_TYPE,
  CHAT_TYPES,
} from 'common_constants/business';
import { ROUTES } from 'common_constants/routes';
import { request, warn, error, success, info } from '../../tools';
import Box from '../../components/Box';
import { tools } from 'common_components';

import './styles.scss';

const ConfirmAddContract = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.common.users);
  const client = useSelector((state) => state.common.currentClient);
  const hotlineList = useSelector((state) => state.common.currentHotlineList);
  const reachList = useSelector((state) => state.common.currentReachList);
  const { data, hotline, prev, fromWallet } = useSelector((state) => state.common.modal);
  const activationInsurance = useSelector((state) => state.common.activationInsurance);
  const FILII = useSelector((state) => state.common.FILII);

  const [loading, setLoading] = useState(false);

  const { contract } = data;

  const _contract = { ...contract };

  const onSubmit = async () => {
    if (!client._id) {
      await sendAddClient(client);
    }
    // * should exist one of the following id of client
    if (!client._id && !_contract.C) return;

    await sendAddContract(_contract);
  };

  const addBonusAfterAddClient = (ph, _id) => {
    const validation = (ph, _id) => {
      if (!ph || !_id) {
        warn('', 'Для створення контракту, потрібні дані для нього.', { ph: ph, _id: _id });
        return;
      }

      return true;
    };

    if (!validation(ph, _id)) return;

    setLoading(true);

    const transaction = {
      _id: _id,
      ph: ph,
    };

    request.post(
      '/clients/addBonusAfterAddClient',
      transaction,
      (req) => {
        if (req.psa5) {
          info('Клієнт проходить по "Проєкту 3000"', 'Клієнту нараховувано 3000 бонусів');
        }
      },
      error,
    );

    setLoading(false);
  };

  const sendAddClient = async (client) => {
    const validation = (client) => {
      if (!client) {
        warn('', 'Для створення контракту, потрібні дані для нього.', client);
        return;
      }

      return true;
    };

    if (!validation(client)) return;

    setLoading(true);

    request.post(
      '/clients/add',
      client,
      (req) => {
        if (req.duplicatePhone) {
          warn('', req.duplicatePhone ? 'Клієнт з таким номером вже існує.' : '', req);
          return;
        }
        success('', 'Клієнта створено.');
        dispatch(setCurrentClient({ ...client, _id: req.new_id }));
        addBonusAfterAddClient(client.ph, req.new_id);
        _contract.C = req.new_id;
        sendAddContract(_contract);
      },
      error,
    );

    setLoading(false);
  };

  const sendAddContract = async (contract) => {
    const validation = (contract) => {
      if (!contract) {
        warn('', 'Для створення контракту, потрібні дані для нього.', contract);
        return;
      }
      if (contract?.rb && contract?.pa < RED_BUTTON_PRICE) {
        return warn('', `Вартість тривожної кнопки не може бути менше ${RED_BUTTON_PRICE}₴`);
      }
      return true;
    };

    if (!validation(contract)) return;

    setLoading(true);

    const { files, ...shortQuestionnaireWithoutFiles } = contract.shortQuestionnaire;

    await request.post(
      '/contracts/add',
      { ...contract, shortQuestionnaire: shortQuestionnaireWithoutFiles, activationInsurance },
      (req) => {
        if (req.activateInsurance ?? false) {
          success('', 'Контракт створено.');
          dispatch(updateInsurance({ activationInsurance: activationInsurance, contractId: req.contractId, dateActivation: req.dateActivation }));
          dispatch(setModal());
          dispatch(setCurrentClient());
          dispatch(setActivationInsurance());
          return;
        }

        if (req?._id && files?.length > 0) {
          const formData = new FormData();
          for (let file of files) formData.append('files', file, tools.translit(file.name));

          formData.append('type', CHAT_TYPES.chatWithClient.key);
          formData.append('chatId', req._id);

          request.post(
            '/chatPrivate/chatSendFiles',
            formData,
            ({ newMessage }) => {
              editContract({ chatWithClient: newMessage, _id: req._id });
            },
            error,
          );
        }

        const _currentHotlineList =
          pathname === ROUTES.HOTLINE_REACH
            ? reachList.map((i) => {
                return i._id === hotline?._id ? { ...i, K: req._id, C: client?._id } : i;
              })
            : hotlineList.map((i) => {
                return i._id === hotline?._id ? { ...i, K: req._id, C: client?._id } : i;
              });

        success('', 'Контракт створено.');
        dispatch(setModal());
        pathname === ROUTES.HOTLINE_REACH ? dispatch(setCurrentReachList(_currentHotlineList)) : dispatch(setCurrentHotlineList(_currentHotlineList));
        dispatch(setCurrentClient());
      },
      error,
    );

    setLoading(false);
  };

  const showInsurancePeriod = () => {
    if (contract.insurance) {
      const insuranceType = Object.keys(contract.insurance)[0];
      return `${contract.insurance[insuranceType].startDate} по ${contract.insurance[insuranceType].endDate}`;
    }
    return 'Відсутнє';
  };

  const showIncomes = () => {
    if (contract.incomes) {
      return (
        <ul className="income-list">
          {contract.incomes.map((income, index) => (
            <li key={index}>
              <p>Тип: {INCOME_TYPES[income.type]}</p>
              {income.date && <p>Дата: {dayjs(income.date).format('DD-MM-YYYY')}</p>}
              {income.amount && <p>Сума: {income.amount}</p>}
              {income.date2 && <p>Дата2: {dayjs(income.date2).format('DD-MM-YYYY')}</p>}
              {income.outdated && <p>Застаріла: {income.outdated ? 'Так' : 'Ні'}</p>}
            </li>
          ))}
        </ul>
      );
    }
    return 'Відсутнє';
  };

  return (
    <Modal
      open
      className="contract-confirm"
      title={fromWallet ? 'Інформація про контракт' : 'Підтвердження даних контракту'}
      onCancel={() => dispatch(setModal(prev))}
      footer={null}
    >
      <Spin tip="Завантаження" spinning={loading}>
        <Space
          direction="vertical"
          size="middle"
          style={{
            display: 'flex',
            marginTop: '20px',
          }}
        >
          <Card title="Дані договору" className="wrapper">
            <span>
              <b>Страхування:</b> {showInsurancePeriod()}
            </span>
            <span>
              <b>Доходи:</b> {showIncomes()}
            </span>
            <span>
              <b>Дата підписання:</b> {dayjs(contract.sd)?.format('DD-MM-YYYY') ?? '-'}
            </span>
            <span>
              <b>Філія:</b> {FILII[contract.f] ?? '-'}
            </span>
            <span>
              <b>Уклав:</b> {users[contract.us]?.name ?? '-'}
            </span>
            <span>
              <b>Хто буде обслуговувати:</b> {SERVIS_OPTIONS[contract.un] ?? '-'}
            </span>
            <span>
              <b>Повторно:</b> {REPEATS[contract.tm] ?? '-'}
            </span>
            <span>
              <b>ПІБ:</b> {client.n ?? '-'}
            </span>
            <span>
              <b style={{ color: 'red' }}>Тривожна кнопка:</b> {contract.rb ? 'Так' : 'Ні'}
            </span>
            <span>
              <b>ОВ:</b> {contract.ov1 ?? '-'} {contract.ov2 ?? '-'} {contract.ov3 ?? '-'}
              {contract.ov1 === '' && contract.ov2 === '' && contract.ov3 === '' ? '-' : ''}
            </span>
            <span>
              <b>Адреса:</b> {client.a ?? '-'}
            </span>
            <span>
              <b>Телефон:</b> {client.ph ?? '-'}
            </span>

            <span>
              <b>Фабула справи:</b> {contract?.shortQuestionnaire?.plotCase ?? '-'}
            </span>
            <span>
              <b>Що хоче клієнт:</b> {contract?.shortQuestionnaire?.customerWants ?? '-'}
            </span>
            <span>
              <b>Що обіцяли клієнту:</b> {contract?.shortQuestionnaire?.promiseClient ?? '-'}
            </span>
            <span>
              <b>Дата кінцевого результату:</b>{' '}
              {contract?.shortQuestionnaire?.notDateFinalResult === undefined
                ? 'Термін не обговорювався'
                : dayjs(contract?.shortQuestionnaire?.dateFinalResult).format('DD-MM-YYYY')}
            </span>
            <span>
              <b>Примітка:</b> {contract?.shortQuestionnaire?.note ?? '-'}
            </span>
            {contract?.shortQuestionnaire?.tasks && (
              <span>
                <b>Завдання</b>
                <div>
                  {contract?.shortQuestionnaire?.tasks.map((task, index) => (
                    <div key={index} style={{ margin: '5px 0px 5px 10px' }}>
                      <b>Завдання №{index + 1}</b> <br />
                      <p style={{ marginLeft: '5px', marginTop: '5px' }}>
                        <b>Опис:</b> {task.description} <br />
                        <b>Термін:</b> {task.notDate ? 'Термін не обговорювався' : dayjs(task.date).format('DD-MM-YYYY')}
                      </p>
                    </div>
                  ))}
                </div>
              </span>
            )}
            {contract?.shortQuestionnaire?.files && (
              <span>
                <b>Файли</b>
                <div>
                  {contract.shortQuestionnaire.files.map((file, index) => (
                    <p key={index}>{file.name}</p>
                  ))}
                </div>
              </span>
            )}

            <span>
              <b>Що саме:</b> {HOTLINE_ADV_WHERE_MORE[contract.am] ?? '-'}
            </span>
            <span>
              <b>Предмет договору:</b> {contract.ci ?? '-'}
            </span>
            <span>
              <b>Своїми словами:</b> {contract.cd ?? '-'}
            </span>
            <span>
              <b>Форма гонорару:</b> {PAYMENT_FORMAT[contract.pf] ?? '-'}
            </span>
            <span>
              <b>Сума гонорару:</b> {contract.pa ?? '-'}
            </span>
            <span>
              <b>Кількість місяців:</b> {contract.pm === 13 ? 'Бескінечно' : contract.pm ?? '-'}
            </span>
            <span>
              <b>Вихід адвоката:</b> {contract.ea ?? '-'}
            </span>
            <span>
              <b>Філія:</b> {FILII[+hotline?.fil] ?? '-'}
            </span>
            <span>
              <b>Дата звернення:</b> {dayjs(hotline?.date)?.format('DD-MM-YYYY') ?? '-'}
            </span>
            <span>
              <b>Хто проводив:</b> {users[hotline?.amm]?.name ?? '-'}
            </span>
            <span>
              <b>Офіс/дист:</b> {contract.od === 1 ? 'Дист' : 'Офіс'}
            </span>
          </Card>

          <Card title="Дані клієнта" className="wrapper">
            <span>
              <b>ПІБ:</b> {client.n ?? '-'}
            </span>
            <span>
              <b>Стать:</b> {client.se === 1 ? 'ч' : 'ж'}
            </span>
            <span>
              <b>Філія:</b> {FILII[client.f] ?? '-'}
            </span>
            <span>
              <b>ФО/ФОП/ЮО:</b> {FORM_OF_BUSINESS[client.u] ?? '-'}
            </span>
            <span>
              <b>День народження:</b> {client?.b ? dayjs(client.b)?.format('DD-MM-YYYY') ?? '-' : '-'}
            </span>
            <span>
              <b>Місто народження:</b> {TOWNS[client.t] ?? '-'}
            </span>
            <span>
              <b>Адреса:</b> {client.a ?? '-'}
            </span>
            <span>
              <b>Тип паспорта:</b> {PASSPORT_TYPE[client.pt] ?? '-'}
            </span>
            <span>
              <b>Серія та номер:</b> {client.pn ?? '-'}
            </span>
            <span>
              <b>Ким виданий:</b> {client.pkv ?? '-'}
            </span>
            <span>
              <b>Дата видачі:</b> {client?.pdv ? dayjs(client.pdv)?.format('DD-MM-YYYY') ?? '-' : '-'}
            </span>
            <span>
              <b>Дійсний до:</b> {client?.pdd ? dayjs(client.pdd)?.format('DD-MM-YYYY') ?? '-' : '-'}
            </span>
            <span>
              <b>ІПН:</b> {client.inn ?? '-'}
            </span>
            <span>
              <b>Телефон:</b> {client.ph ?? '-'}
            </span>
            <span>
              <b>E-mail:</b> {client.email ?? '-'}
            </span>
            <span>
              <b>Професія:</b> {JOBS[client.j] ?? '-'}
            </span>
            <span>
              <b>Соц статус:</b> {SOC_STATUS[client.ss] ?? '-'}
            </span>
            <span>
              <b>Службове становище:</b> {WORK_STATES[client.ws] ?? '-'}
            </span>
            <span>
              <b>Хобі:</b> {HOBBIES[client.h] ?? '-'}
            </span>
            <span>
              <b>Зв`язки:</b>&ensp;
              {client.r?.map((ri, step, arrRI) => `${RELATIONS[ri.name] || '?'} - ${ri.phone || '?'} ${step === arrRI.length - 1 ? '' : '|'} `) ??
                '-'}
            </span>
            <span>
              <b>Характеристики:</b>&ensp;
              {[client.ch1 ?? '-', client.ch2 ?? '-', client.ch3 ?? '-', client.ch4 ?? '-', client.ch5 ?? '-', client.ch6 ?? '-']?.map(
                (item, step, arrCH) => `${CHARACTERISTIC[step] ?? '?'} - ${item} ${step === arrCH.length - 1 ? '' : '|'}  `,
              ) ?? '-'}
            </span>
          </Card>

          <Box mt={10} className="group-button">
            <Button className="btn-add" type="primary" onClick={onSubmit} disabled={fromWallet}>
              Підтвердити
            </Button>
            <Button className="btn-add" type="primary" danger onClick={() => dispatch(setModal(prev))}>
              Назад
            </Button>
          </Box>
        </Space>
      </Spin>
    </Modal>
  );
};

export default ConfirmAddContract;
