import React, { useRef, useEffect } from 'react';

import Message from './Message';

const MessageRow = ({ data, index, setSize, handleOnContextMenu, hasNext, handleDownloadFile }) => {
  const rowRef = useRef();

  let message = null;

  if (hasNext) {
    message = data[index - 1];
  } else {
    message = data[index];
  }

  useEffect(() => {
    setSize(index, rowRef.current?.getBoundingClientRect()?.height ?? 0);
  }, [setSize, index]);

  if (message?.removed) return <></>;

  return (
    <div ref={rowRef} onContextMenu={(e) => handleOnContextMenu(e, message)}>
      <Message item={message} onDownload={() => handleDownloadFile(message.fileId, message.fileName)} />
    </div>
  );
};

export default MessageRow;
