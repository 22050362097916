import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spin, Select, Button, Input, Form, Typography } from 'antd';
import dayjs from 'dayjs';
import { INSURANCE_PERIOD, PAY_PURPOSES, COUNTRY_PHONE_CODES } from 'common_constants/business';
import { request, error, warn, success } from '../../tools';

import { setModal, addInsurance } from '../../store/commonReducer';

import './styles.scss';
import phoneNumberValidator from 'common_components/src/tools/phoneNumberValidator';
import CountryPhCode from '../../components/CountryPhCode';

const { Option } = Select;
const { Item } = Form;
const { Text } = Typography;

const AddInsurance = () => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.common.userAuth);
  const [loading, setLoading] = useState(false);
  const [phoneLoading, setPhoneLoading] = useState(false);
  const [user, setUser] = useState(false);
  const [optionsSu, setOptionsSu] = useState([]);
  const [currentSugarsSelect, setCurrentSugarsSelect] = useState([]);
  const [currentSugar, setCurrentSugar] = useState({});
  const [currentClient, setCurrentClient] = useState(null);

  const insuranceTypes = useSelector((state) => state.common.allInsuranceTypes);

  const [name, setName] = useState('');
  const [comment, setComment] = useState('');
  const [phone, setPhone] = useState('');
  const [countryPhCode, setCountryPhCode] = useState(COUNTRY_PHONE_CODES.UA);

  const [insurancePeriod, setInsurancePeriod] = useState(6);
  const insuranceStartDate = dayjs().add(1, 'day').format('DD-MM-YYYY');
  const [insuranceEndDate, setInsuranceEndDate] = useState(dayjs().add(insurancePeriod, 'month').add(1, 'day').format('DD-MM-YYYY'));
  const [insuranceState, setInsuranceState] = useState({
    type: null,
    payment: 2,
  });
  const [insurancePrice, setInsurancePrice] = useState(null);

  const [insuranceType, setInsuranceType] = useState({});

  const findInsuranceType = () => {
    const insuranceType = insuranceTypes.find((el) => el.i === insuranceState.type);
    setInsurancePrice(insuranceType.minimalPrice[insurancePeriod]);
    return insuranceType;
  };

  const handleClick = async () => {
    if (insuranceState.type === null) return warn('Додавання страховки', 'Потрібно обрати тип страховки');
    if (currentSugar === null) return warn('Додавання страховки', 'Потрібно обрати сахарок');
    if (currentSugar.amount < insurancePrice)
      return warn(
        'Додавання страховки',
        `Сума сахарку (${currentSugar.amount}) повинна буті більшою чи дорівнювати мінімальному платежу за період (${insurancePrice})`,
      );

    setLoading(true);
    const reqBody = {
      ...insuranceState,
      phone,
      countryPhCode: countryPhCode,
      comment: comment,
      period: insurancePeriod,
      sugarNumber: currentSugar.i,
      name,
    };

    await request.post(
      '/insurance/addInsurance',
      reqBody,
      ({ insurance }) => {
        dispatch(addInsurance(insurance));
        setLoading(false);
        setInsuranceState({ ...insuranceState, comment: '' });
        setComment('');
        setPhone('');
        setCurrentSugar(null);
        setName('');
        success('', 'Страховка успішно додана');
        dispatch(setModal());
      },
      () => {
        setLoading(false);
        error();
      },
    );
  };

  const getNameByPhone = async () => {
    setPhoneLoading(true);
    const body = { phone, countryPhCode };

    await request.post(
      '/hotline/getNameByPhone',
      body,
      ({ data }) => {
        setName(data.name);
        setCurrentClient(data);
        setUser(true);
      },
      error,
    );

    setPhoneLoading(false);
  };

  const getSugarByNumber = async () => {
    setLoading(true);
    const body = {
      ph: phone,
      payfor: '8',
      user: userAuth,
    };

    await request.post(
      '/sugar/getSugarByClientPhone',
      body,
      ({ sugars }) => {
        if (sugars?.length < 1) {
          warn('', 'Ви ще не створили сахарок для цього користувача');
          return;
        }
        const options = sugars.map((sugar) => ({
          value: sugar.i.toString(),
          label: sugar.i.toString(),
        }));
        setCurrentSugarsSelect(sugars);
        setOptionsSu(options);
      },
      error,
    );

    setLoading(false);
  };

  const handleSugarSelect = (value) => {
    const curSugar = currentSugarsSelect.find((sugar) => sugar.i === parseInt(value));
    setCurrentSugar(curSugar);
    setOptionsSu([
      {
        value: value,
        label: value,
      },
    ]);
    success('', 'Сахарко знайдено');
  };

  useEffect(() => {
    if (insuranceState.type !== null) {
      setInsuranceType(findInsuranceType());
    }
  }, [insuranceState.type]);

  const handlePhoneNumberInput = (event) => {
    const validPhoneNumber = phoneNumberValidator(event);

    setPhone(validPhoneNumber);
  };

  useEffect(() => {
    if (currentClient?.name) {
      getSugarByNumber();
    }
  }, [currentClient]);

  return (
    <Modal className="modalAddInsurance" title={'Додати страховку'} open onCancel={() => dispatch(setModal())} footer={null}>
      <Spin spinning={loading}>
        <div className="row">
          <div className="box phone">
            <div className="countryPhCode-wrapper">
              <h4>Код країни </h4>
              <CountryPhCode value={countryPhCode} onChange={setCountryPhCode} />
            </div>

            <div className="countryPh-wrapper">
              <h4>Номер телефону </h4>
              <Input
                style={{ width: 150 }}
                suffix={<Spin spinning={phoneLoading} />}
                loading={true}
                placeholder="Телефон"
                value={phone}
                onChange={(e) => {
                  handlePhoneNumberInput(e);
                }}
                onBlur={() => {
                  if (phone !== '') getNameByPhone();
                }}
              />
            </div>
          </div>
          <div className="box sugarNumber">
            <div className="item-dateWrappwe">
              <Text>Номер сахарка: </Text> &nbsp;
              <Item name="su">
                <Select options={optionsSu} onSelect={handleSugarSelect} rules={[{ required: true }]} disabled={!currentClient}>
                  {optionsSu.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Item>
            </div>
          </div>
          <div className="box full">
            <h4>ПІБ Клієнта</h4>
            <Input
              value={name}
              placeholder="Введіть ПІБ"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="box">
            <h4>Період</h4>
            <Select
              value={insurancePeriod}
              style={{
                width: 120,
              }}
              onChange={(value) => {
                setInsurancePeriod(value);
                setInsuranceEndDate(dayjs(insuranceStartDate, 'DD-MM-YYYY').add(value, 'month').format('DD-MM-YYYY'));
                setInsurancePrice(insuranceType?.minimalPrice?.[value]);
              }}
              options={Object.keys(INSURANCE_PERIOD).map((e) => ({
                value: Number(e),
                label: INSURANCE_PERIOD[e],
              }))}
            />
          </div>
          <div className="box">
            <h5>Дата початку</h5>
            {insuranceStartDate}
            <h5>Дата закінчення</h5>
            {insuranceEndDate}
            <h5>Сума сахарка</h5>
            {currentSugar ? currentSugar.amount : 'Немає сахарка'}
          </div>
          <div className="box"></div>
          <div className="box full">
            <h4>Тип страховки</h4>
            <Select
              onChange={(value) => setInsuranceState({ ...insuranceState, type: Number(value) })}
              options={insuranceTypes.map((key) => ({
                value: key.i,
                label: key.name,
              }))}
            />
          </div>
          <div className="box full">
            <h4>Коментар</h4>
            <Input value={comment} onChange={(e) => setComment(e.target.value)} />
          </div>
        </div>
        <Button type="primary" className="button" onClick={handleClick} disabled={!name || !user}>
          Додати страховку
        </Button>
      </Spin>
    </Modal>
  );
};

export default AddInsurance;
