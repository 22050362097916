import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Menu, Button, Layout, Badge, Row } from 'antd';
import { red, volcano, cyan, green, gray, purple, yellow } from '@ant-design/colors';
import {
  BankOutlined,
  BookOutlined,
  FileDoneOutlined,
  FileSearchOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  MailFilled,
  MenuOutlined,
  MergeCellsOutlined,
  MessageFilled,
  MessageOutlined,
  MoneyCollectOutlined,
  PieChartOutlined,
  PlusCircleFilled,
  ReadOutlined,
  SearchOutlined,
  SolutionOutlined,
  TabletFilled,
  TeamOutlined,
  UnorderedListOutlined,
  DollarOutlined,
  UserOutlined,
  UserSwitchOutlined,
  MailOutlined,
  DislikeOutlined,
  ScheduleOutlined,
  TransactionOutlined,
  AlertOutlined,
  CreditCardOutlined,
  LineChartOutlined,
  CodeOutlined,
  KeyOutlined,
  CalendarOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  RedEnvelopeOutlined,
  BarChartOutlined,
  LikeOutlined,
  InteractionOutlined,
  InsuranceOutlined,
  MehOutlined,
  TwitterOutlined,
  FireOutlined,
  RobotOutlined,
  PlaySquareOutlined,
  WechatFilled,
  InsertRowAboveOutlined,
  WalletOutlined,
  ContactsOutlined,
  IdcardOutlined,
  LikeFilled,
  BellOutlined,
  GithubOutlined,
  CopyOutlined,
  NodeExpandOutlined,
  SmileOutlined,
  NodeCollapseOutlined,
  HeartOutlined,
} from '@ant-design/icons';
import { ROLES, CHAT_TYPES } from 'common_constants/business';
import { ROUTES } from 'common_constants/routes';
import { ACTUALIZATION } from 'common_constants/modals';

import { setMobSideBar } from '../../store/uiReducer';
import UserAvatar from '../UserAvatar';
import ProcessHotlineModal from './ProcessHotlineModal';
import { warn, request, success, error } from '../../tools';
import ActivitySwitcher from './ActivitySwitcher';
import './styles.scss';
import { setModal, addUnreadedMessages } from '../../store/commonReducer';
import dayjs from 'dayjs';

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const MainMenu = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const userAuth = useSelector((state) => state.common.userAuth);
  const appPrepared = useSelector((state) => state.common.appPrepared);
  const userInfo = useSelector((state) => state.common.currentUserInfo);
  const totalUncompletedTasks = useSelector((state) => state.common.totalUncompletedTasks);
  const { mobSidebar, mob } = useSelector((state) => state.ui);
  const unreadedMessagesTotal = useSelector((state) => state.common.unreadedMessagesTotal);
  const unreadedMessagesData = useSelector((state) => state.common.unreadedMessagesData);
  const unwatchedTendersCount = useSelector((state) => state.common.unwatchedTendersCount);
  const unwatchedTasksCount = useSelector((state) => state.common.unwatchedTasksCount);
  const unwatchedRedButtonContractMessages = useSelector((state) => state.common.unreadedMessagesRedButtonContractsChatWithClient);
  const actualization = useSelector((state) => state.common.actualization);
  const users = useSelector((state) => state.common.users);
  const currentUser = users?.[userAuth?._id];

  const [showHotlineModal, setShowHotileModal] = useState(false);
  const [selected, setSelected] = useState(pathname);
  const [unreadedMessagesContractsChat, setUnreadedMessagesContractsChat] = useState(0);
  const [unreadedMessagesContractsChatWithClient, setUnreadedMessagesContractsChatWithClient] = useState(0);

  const { role = 99 } = userAuth;

  useEffect(() => {
    const currentUnreadedMessagesContractsChat = unreadedMessagesData
      ?.filter((item) => item.chatType === CHAT_TYPES.contractChat.key)
      ?.reduce((acc, item) => acc + 1, 0);

    const currentUnreadedMessagesContractsChatWithClient = unreadedMessagesData
      ?.filter((item) => item.chatType === CHAT_TYPES.chatWithClient.key)
      ?.reduce((acc, item) => acc + 1, 0);

    setUnreadedMessagesContractsChat(currentUnreadedMessagesContractsChat);
    setUnreadedMessagesContractsChatWithClient(currentUnreadedMessagesContractsChatWithClient);
  }, [unreadedMessagesData]);

  const admin = ROLES[role] === ROLES.admin;
  const inspector = ROLES[role] <= ROLES.inspector;
  const manager = ROLES[role] <= ROLES.manager;
  const blockManager = ROLES[role] <= ROLES.blockManager;
  const deputyManager = ROLES[role] <= ROLES.deputyManager;
  const rabfil = ROLES[role] <= ROLES.rabfil;
  const worker = ROLES[role] <= ROLES.worker;
  const psychologistOnly = ROLES[role] === ROLES.psychologist;

  const { bellUser, bellUserAnk, bellUserZsu, clientsHotlinesAll, hackworkAll } = userInfo,
    badge_next = (type, color) => {
      return (
        <span>
          &nbsp;
          <Badge count={type ?? ''} showZero color={color ? color : red.primary} style={{ boxShadow: 'none', marginBottom: '3px' }} />
        </span>
      );
    };

  const items = [
    (inspector || userAuth.contactCenter) && getItem('Гаряча лінія', ROUTES.HOTLINE_CALLS, <FireOutlined />),
    worker && getItem('Пошук', ROUTES.SEARCH, <SearchOutlined />),
    worker && getItem('Додати запис', ROUTES.HOTLINE, <PlusCircleFilled />),
    worker &&
      getItem(
        <span>
          Записи
          {!inspector && userInfo?.clientsHotlinesAll > 0 && badge_next(clientsHotlinesAll, cyan.primary)}
          {userInfo?.hackworkAll > 0 && badge_next(hackworkAll, green.primary)}
          {userInfo?.bellUser > 0 && badge_next(bellUser, volcano.primary)}
          {userInfo?.bellUserAnk > 0 && badge_next(bellUserAnk, volcano.primary)}
          {userInfo?.bellUserZsu > 0 && badge_next(bellUserZsu, volcano.primary)}
          {inspector && userInfo?.clientsHotlinesAll > 0 && badge_next(clientsHotlinesAll, cyan.primary)}
          {deputyManager && unwatchedTendersCount > 0 && badge_next(unwatchedTendersCount, purple.primary)}
        </span>,
        'hotlineZone',
        null,
        [
          getItem(
            <span>
              Календар
              {!deputyManager && userInfo?.clientsHotlinesAll > 0 && badge_next(clientsHotlinesAll, cyan.primary)}
            </span>,
            ROUTES.LIST,
            <CalendarOutlined />,
          ),
          rabfil &&
            getItem(
              <span>
                Халтури
                {userInfo?.hackworkAll > 0 && badge_next(hackworkAll, green.primary)}
              </span>,
              ROUTES.HACKWORKS,
              <FileDoneOutlined />,
            ),
          rabfil &&
            getItem(
              <span>
                Дожими
                {userInfo?.bellUser > 0 && badge_next(bellUser, volcano.primary)}
              </span>,
              ROUTES.HOTLINE_REACH,
              <MergeCellsOutlined />,
            ),
          currentUser?.brigadeUF === true &&
            getItem(
              <span>
                Дожими АНК
                {userInfo?.bellUserAnk > 0 && badge_next(bellUserAnk, volcano.primary)}
              </span>,
              ROUTES.DOZHIMS_ANK,
              <NodeExpandOutlined />,
            ),
          currentUser?.brigadeZSU === true &&
            getItem(
              <span>
                Дожими ЗСУХ
                {userInfo?.bellUserZsu > 0 && badge_next(bellUserZsu, volcano.primary)}
              </span>,
              ROUTES.DOZHIMS_ZSU,
              <NodeCollapseOutlined />,
            ),
          deputyManager &&
            getItem(
              <span>
                Записи клієнтів
                {deputyManager && userInfo?.clientsHotlinesAll > 0 && badge_next(clientsHotlinesAll, cyan.primary)}
              </span>,
              ROUTES.HOTLINES_BY_CLIENTS,
              <UnorderedListOutlined />,
            ),
          deputyManager &&
            getItem(
              <span>Тендери {unwatchedTendersCount > 0 && badge_next(unwatchedTendersCount, purple.primary)}</span>,
              ROUTES.TENDERS,
              <RedEnvelopeOutlined />,
            ),
          rabfil && getItem('Невнесенні сплати', ROUTES.FAILURE_TO_PAY, <AlertOutlined />),
        ],
      ),

    // deputyManager && getItem('Клієнти', ROUTES.CLIENTS, <TeamOutlined />),
    worker &&
      getItem(
        <span>
          Клієнти
          {rabfil && totalUncompletedTasks > 0 && badge_next(totalUncompletedTasks, red.primary)}
        </span>,
        'client_group',
        null,
        [
          rabfil &&
            getItem(
              <span>
                Договори
                {rabfil && totalUncompletedTasks > 0 && badge_next(totalUncompletedTasks, red.primary)}
              </span>,
              ROUTES.CONTRACTS,
              <FileProtectOutlined />,
            ),
          rabfil && getItem('Виходи', ROUTES.ASSIGNMENT, <BankOutlined />),
          worker && getItem('Кореспонденція', ROUTES.CORRESPONDENCE_PAGE, <MailOutlined />),
          rabfil && getItem('Страховки', ROUTES.INSURANCES, <InsuranceOutlined />),
          rabfil && getItem('Клубні карти', ROUTES.CLUB_CARDS, <IdcardOutlined />),
          manager && getItem('Оцінка якості', ROUTES.QUESTIONNAIRES, <LikeFilled />),
          rabfil &&
            getItem(
              <span>
                Завдання
                {rabfil && unwatchedTasksCount > 0 && badge_next(unwatchedTasksCount, red.primary)}
              </span>,
              ROUTES.TASKS,
              <ScheduleOutlined />,
            ),
        ],
      ),

    worker &&
      getItem('Гроші', 'money', null, [
        admin && getItem('Рахунки та Картки', ROUTES.BANK_CARDS, <CreditCardOutlined />),
        rabfil && getItem('Жнива', ROUTES.HARVEST, <MoneyCollectOutlined />),
        worker && getItem('Витрати', ROUTES.EXPENSE, <DollarOutlined />),
        blockManager && getItem('Сахорок', ROUTES.SUGAR, <TransactionOutlined />),
        rabfil && getItem('Гаманець', ROUTES.WALLET, <WalletOutlined />),
        inspector && getItem('Баланс філій', ROUTES.WALLET_MONTH_FILIALS, <HeartOutlined />),
      ]),

    // deputyManager &&
    //   getItem(
    //     <span>Термінові чати {unwatchedRedButtonContractMessages > 0 && badge_next(unwatchedRedButtonContractMessages, yellow.primary)}</span>,
    //     ROUTES.RED_BUTTON_CHAT,
    //     <FireOutlined />,
    //   ),

    inspector && getItem('Партнери', ROUTES.PARTNERS, <SolutionOutlined />),
    !window?.ReactNativeWebView &&
      !psychologistOnly &&
      getItem(<span>Чат {unreadedMessagesTotal > 0 && badge_next(unreadedMessagesTotal, red.primary)}</span>, ROUTES.CHAT, <MessageOutlined />),
    deputyManager && getItem('Швидкі питання клієнтів', ROUTES.CLIENTS_QUICK_CHATS, <WechatFilled />),
    rabfil && getItem('Твіттер', ROUTES.TWITTER, <TwitterOutlined />),
    rabfil && getItem('Конфлікти', ROUTES.CONFLICTS, <MehOutlined />),
    deputyManager && getItem('Документи', ROUTES.DOCUMENTS, <RobotOutlined />),
    getItem('Психолог', ROUTES.PSYCHOLOGIST_HOTLINE, <SmileOutlined />),
    rabfil &&
      getItem('Вебсайт', 'website', null, [
        (admin || userAuth?.siteEdit) && getItem('Опублікувати сайт', ROUTES.PUBLISH_WEBSITE, <TabletFilled />),
        getItem('Новини на сайті', ROUTES.WEBSITE_NEWS, <BookOutlined />),
        (admin || userAuth?.siteEdit) && getItem('Відгуки клієнтів', ROUTES.CLIENTS_FEEDBACK, <MailFilled />),
      ]),
    worker &&
      getItem('Додаткові матеріали', 'infoPart', null, [
        deputyManager && getItem('Довідник', ROUTES.PERSONNEL_DIRECTORY, <ContactsOutlined />),
        rabfil && getItem('Розвідка', ROUTES.FEE, <FileSearchOutlined />),
        getItem('Рейтинг', ROUTES.RATING, <PieChartOutlined />),
        getItem('Рейтинг Права', ROUTES.PRAVO_RATING, <LineChartOutlined />),
        rabfil && getItem('Бібліотека', ROUTES.LIBRARY, <ReadOutlined />),
        deputyManager && getItem('Заблоковані', ROUTES.BLOCKED, <UserOutlined />),
        getItem('Помічник', ROUTES.HELPER, <InfoCircleOutlined />),
        deputyManager && getItem('Вебінари', ROUTES.WEBINARS, <PlaySquareOutlined />),
      ]),
    inspector &&
      getItem('Адміністрування', 'adminPart', null, [
        admin && getItem('Філії', ROUTES.FILII, <TeamOutlined />),
        getItem('Кабінети', ROUTES.CABINETS, <InsertRowAboveOutlined />),
        admin && getItem('МФО', ROUTES.MFO, <InteractionOutlined />),
        getItem('Конвеєр розсилок', ROUTES.SENDER, <MailFilled />),
        getItem('Користувачі', ROUTES.USERS, <UserSwitchOutlined />),
        getItem('Активність', ROUTES.ACTIVITY, <BarChartOutlined />),
        getItem('Запізнення', ROUTES.LATENESS, <UserSwitchOutlined />),
        getItem('Журнал', ROUTES.JOURNAL, <BookOutlined />),
        getItem('Доступи', ROUTES.ACCESS_LIST, <KeyOutlined />),
        getItem('Скарги', ROUTES.COMPLAINTS, <DislikeOutlined />),
        getItem('Пропозиції з додатку', ROUTES.SUGGESTIONS, <LikeOutlined />),
        admin && getItem('AI дожими клієнтів', ROUTES.CLIENTS_DOZHIMS_AI_CHATS, <WechatFilled />),
        admin && getItem('Пуш повідомлення', ROUTES.PUSH_NOTIFICATION, <BellOutlined />),
        admin && getItem('Типи страховок', ROUTES.INSURANCE_TYPE, <InsuranceOutlined />),
        admin && getItem('Дублікати', ROUTES.DUBLICATES, <CopyOutlined />),
      ]),
    admin &&
      getItem('Статистика', 'statPart', null, [
        getItem('Причини відмін зустрічей', ROUTES.STATISTIC_MEET_CANCELATION_REASONS, <PieChartOutlined />),
        getItem('Демографія клієнтів', ROUTES.STATISTIC_CLIENTS_DEMOGRAPHY, <PieChartOutlined />),
        // getItem('Використання єАдвокат', ROUTES.STATISTIC_FIL_ACTIVE_CLIENTS_WITH_APP, <PieChartOutlined />),
        getItem('Активні договори', ROUTES.STATISTIC_FIL_ACTIVE_CONTRACTS, <PieChartOutlined />),
        getItem('Архівні цукерки', ROUTES.STATISTIC_FIL_ARCHIVE_CANDY, <PieChartOutlined />),
        getItem('Популярності сторінок "єАдвокат"', ROUTES.STATISTIC_CLIENT_PAGES, <PieChartOutlined />),
        getItem('Анкетування по функціоналу', ROUTES.FUNCTION_SURVEY, <LineChartOutlined />),
        getItem('Заповнення договорів', ROUTES.PAYMENT_MANAGEMENT_FILL_CHART, <LineChartOutlined />),
        getItem('IT послуги', ROUTES.IT_SERVICES, <GithubOutlined />),
      ]),

    admin &&
      getItem('Розробка', 'devPart', null, [
        getItem('Деплой дев серверу', ROUTES.DEVELOPMENT, <CodeOutlined />),
        getItem('Перевірка оплат фонді', ROUTES.FONDY_HOTLINES, <BookOutlined />),
        getItem('Логи користувачів', ROUTES.STATISTIC_USERS_LOGS, <LineChartOutlined />),
        getItem('Логи серверу', ROUTES.STATISTIC_SERVER_LOGS, <LineChartOutlined />),
      ]),
  ];

  const handleClick = (item) => {
    setSelected(item.key);
    history.push(item.key);
  };

  const handleCollapse = (value) => {
    dispatch(setMobSideBar(!value));
  };

  const chat = () => {
    history.push(ROUTES.CHAT);
  };

  const profile = () => {
    history.push(ROUTES.PROFILE);
  };

  const logout = () => {
    window.localStorage.removeItem('accessToken');
    window.location.reload();
  };

  const menuSliderRef = useRef(null);

  const pushActualizationModal = () => {
    dispatch(setModal({ name: ACTUALIZATION }));
  };

  useEffect(() => {
    setSelected(pathname);
    mob && handleCollapse(true);
  }, [pathname]);

  useEffect(() => {
    const handleTabChange = (e) => {
      if (e.visibilityState === 'visible' && inspector && clientsHotlinesAll) {
        warn('Неопрацьовані записи клієнтів: ' + clientsHotlinesAll, ' ');
      }
    };
    document.addEventListener('visibilitychange', () => handleTabChange(document));
  }, [clientsHotlinesAll]);

  useEffect(() => {
    if (!userAuth) return;
    if (!appPrepared) return;
    if (!actualization?.date) {
      pushActualizationModal();
      return;
    }

    const lastActualization = dayjs(actualization?.date);

    if (lastActualization.isBefore(dayjs().startOf('month'))) pushActualizationModal();
  }, [actualization]);

  return (
    <>
      {clientsHotlinesAll ? (
        <ProcessHotlineModal showHotlineModal={showHotlineModal} setShowHotileModal={setShowHotileModal} clientsHotlinesAll={clientsHotlinesAll} />
      ) : null}
      <Row className="menu-header">
        <Button className="chat" type="danger" onClick={chat}>
          {!window?.ReactNativeWebView && (
            <Badge className="chat-badge" count={unreadedMessagesTotal || 0} overflowCount={10}>
              <MessageFilled style={{ fontSize: '1.3rem' }} />
            </Badge>
          )}
        </Button>
        <Button type="danger" className="profile" onClick={profile}>
          <UserAvatar user={currentUser} style={{ width: 32, height: 32 }} />
        </Button>
        <Button type="danger" className="logout" onClick={logout}>
          <LogoutOutlined />
        </Button>
      </Row>
      <div ref={menuSliderRef}>
        <Layout.Sider
          breakpoint="lg"
          collapsedWidth="0"
          className="sidebar"
          width={300}
          collapsed={!mobSidebar}
          style={{ position: 'fixed', zIndex: 100 }}
          onCollapse={handleCollapse}
          trigger={<Button style={{ background: 'transparent', color: gray[1], border: 'none' }} icon={<MenuOutlined />} />}
        >
          <Link to={ROUTES.HOME_DASHBOARD} style={{ display: 'block', width: '100%', textAlign: 'center', padding: 8 }}>
            <img src="/logo.svg" alt="zahist" width={40} />
          </Link>

          {userAuth.username && <ActivitySwitcher />}

          <Menu onClick={handleClick} selectedKeys={[selected]} mode="inline" className="sidebar-list" theme="dark" items={items} />
        </Layout.Sider>
      </div>
      {mobSidebar && mob && (
        <div
          className="overlay"
          onClick={() => {
            // if (!mob) return;
            handleCollapse(mobSidebar);
          }}
        ></div>
      )}
    </>
  );
};

export default React.memo(MainMenu);
