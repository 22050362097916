import React from 'react';
import { Card, Col, Row, List, DatePicker, Spin, Pagination } from 'antd';
import WalletItem from '../WalletItem';
import { pageSizeOptions } from '../../../Helpers';

import './WalletList.scss';

const WalletList = ({ payrollsState, pageSize, onChangePageSize, handleDateChange, handleSetCurrentPage, onChangePage }) => {
  const onShowSizeChange = (current, pageSize) => {
    onChangePageSize(current, pageSize);
  };
  return (
    <Spin spinning={payrollsState.loading} tip="Завантаження...">
      <Row justify={'space-between'} align={'middle'} style={{ margin: '0 0 16px 0' }}>
        <Row>
          <DatePicker placeholder={'місяць'} picker="month" onChange={handleDateChange} value={payrollsState.selectedDate} />
        </Row>
        <Row>{payrollsState.totalAmount.toLocaleString('uk-UA')} грн.</Row>
      </Row>
      <Card className="walletInner">
        <Row className="item" justify={'space-between'} align={'middle'}>
          <Col className="fields-inner" flex={'auto'}>
            <Row className="fields" justify={'space-between'}>
              <Col className="fields-item">
                <Row className="fields-row">
                  <Row className="fields-row__title">Дата</Row>
                  <Row className="fields-row__info"></Row>
                </Row>
              </Col>
              <Col className="fields-item">
                <Row className="fields-row">
                  <Row className="fields-row__title">Сума</Row>
                  <Row className="fields-row__info"></Row>
                </Row>
              </Col>
              <Col className="fields-item">
                <Row className="fields-row">
                  <Row className="fields-row__title">Договір/Консультація</Row>
                  <Row className="fields-row__info"></Row>
                </Row>
              </Col>
              <Col className="fields-item">
                <Row className="fields-row">
                  <Row className="fields-row__title">Інфо</Row>
                  <Row className="fields-row__info"></Row>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <List itemLayout="vertical" size="large" dataSource={payrollsState.data} renderItem={(item) => <WalletItem item={item} />} />
      <Row>
        <Pagination
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          total={payrollsState.totalRecords}
          onChange={onChangePage}
          pageSize={pageSize}
          current={payrollsState.currentPage}
          pageSizeOptions={pageSizeOptions}
        />
      </Row>
    </Spin>
  );
};

export default React.memo(WalletList);
