import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setExpenses, setModal } from '../../store/commonReducer';
import { setMainLoader } from '../../store/uiReducer';

import { Button, Card, Pagination, Spin, Form, InputNumber, DatePicker, Typography, Radio, Divider, Empty, Row as AntdRow } from 'antd';
import { SearchOutlined, PlusCircleOutlined, SmallDashOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';

import { Row, ExcelBtn, Box } from 'common_components';
import { ADD_EXPENSE } from 'common_constants/modals';
import { EXPENSE_CATEGORIES, ROLES } from 'common_constants/business';
import { PAGE_SIZE_OPTIONS } from 'common_constants/db';

import { error, request } from '../../tools';
import ExpenseInfo from './ExpenseInfo';
import SearchSelectFil from '../../components/SearchSelect/SearchSelectFil';
import SearchSelectCategory from '../../components/SearchSelect/SearchSelectCategory';
import SearchSelectSubCategory from '../../components/SearchSelect/SearchSelectSubCategory';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';
import SearchSelectAuthor from '../../components/SearchSelect/SearchSelectAuthor';
import PageInfo from '../../components/PageInfo/PageInfo';

const Expense = () => {
  const dispatch = useDispatch();

  const [users, userAuth, FILII, expenses] = [
    useSelector((state) => state.common.users),
    useSelector((state) => state.common.userAuth),
    useSelector((state) => state.common.FILII),
    useSelector((state) => state.common.expenses || []),
  ];

  const { Text } = Typography;
  const { Item } = Form;
  const { Group } = Radio;
  const ButtonR = Radio.Button;
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();

  const tabs = [
    { label: 'Всі', value: '' },
    { label: 'Мої передасти', value: 'myOnly' },
  ];

  const inspector = ROLES[userAuth.role] <= ROLES.inspector;
  const admin = ROLES[userAuth.role] === ROLES.admin;
  const managerOnly = ROLES[userAuth.role] === ROLES.manager;
  const moreThanManager = ROLES[userAuth.role] > ROLES.manager;

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_OPTIONS[0]);
  const [loading, setLoading] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({});
  const [sortNumber, setSortNumber] = useState(0);
  const [sortDate, setSortDate] = useState(0);
  const [exportData, setExportData] = useState(null);
  const [exportRange, setExportRange] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [tab, setTab] = useState(tabs[0].value);

  const getAllExpenses = async (limits) => {
    setLoading(true);

    const params = { ...limits, ...currentFilters, sortNumber, sortDate };

    await request.post(
      '/expense/getExpenses',
      params,
      ({ expenses, total }) => {
        dispatch(setExpenses(expenses));
        setTotal(total);
      },
      error,
    );

    setLoading(false);
  };

  const handleSortNumberChange = (value) => {
    setSortNumber(value);
    setSortDate(0);
    handleFilterChange({ ...currentFilters, sortNumber: value });
  };

  const handleSortDateChange = (value) => {
    setSortDate(value);
    setSortNumber(0);
    handleFilterChange({ ...currentFilters, sortDate: value });
  };

  const handleFilterChange = (values) => {
    setCurrentFilters({ ...values, subCategory: selectedSubCategory });
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getAllExpenses({ page, pageSize, ...currentFilters, tab });
  };

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
    setCurrentPage(1);
    getAllExpenses({ page: 1, pageSize: size, ...currentFilters }, tab);
  };

  const handleChangeTab = (e) => {
    setTab(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    const limits = {
      page: currentPage,
      pageSize,
      ...currentFilters,
      sortNumber,
      sortDate,
      tab,
    };
    getAllExpenses(limits);
  }, [currentPage, currentFilters, sortNumber, sortDate, tab, pageSize]);

  const loadExport = () => {
    dispatch(setMainLoader(true));

    request.post('/expense/export', { exportRange }, ({ data }) => {
      setExportData(data);
      dispatch(setMainLoader(false));
    });
  };

  const formatedExportData =
    exportData &&
    exportData
      .sort((a, b) => a.i - b.i)
      .map((item) => {
        const categoryData = EXPENSE_CATEGORIES[item.category];

        const categoryLabelToDisplay = categoryData
          ? item.category === 10
            ? categoryData.adCategories.find((subcat) => subcat.value === item.adCategory)?.label || categoryData.label
            : categoryData.label
          : item.category;

        const subCategoryLabelToDisplay =
          item.category === 0
            ? categoryData?.subcategories.find((subcat) => subcat.value === parseInt(item.subCategory, 10))?.label || item.subCategory
            : categoryData?.subcategories[item.subCategory]?.label || item.subCategory;

        return [
          item.i,
          FILII[item.fil],
          item.date && dayjs(item.date).format('DD.MM.YYYY HH:mm'),
          item.amount,
          users[item.author]?.name,
          categoryLabelToDisplay,
          subCategoryLabelToDisplay,
          item.comment,
          item.createdAt && dayjs(item.createdAt).format('DD.MM.YYYY HH:mm'),
        ];
      });

  formatedExportData?.unshift(['№ витрати', 'Філія', 'Дата витрати', 'Сума', 'Автор', 'Категорія', 'Підкатегорія', 'Коментар', 'Дата створення']);

  if (!users) return null;

  return (
    <div className="clients_page default_page">
      <AntdRow justify={'center'} align={'middle'}>
        <h2>Витрати</h2>
        <PageInfo page="expenses" />
      </AntdRow>
      <Row style={{ justifyContent: 'center' }}>
        <Button type="primary" onClick={() => dispatch(setModal({ name: ADD_EXPENSE }))}>
          <PlusCircleOutlined /> Додати
        </Button>
      </Row>
      <br />
      {admin && (
        <Row style={{ justifyContent: 'center' }}>
          {exportData ? (
            <CSVLink asyncOnClick={true} data={exportData && formatedExportData}>
              <ExcelBtn style={{ width: 'auto' }}>Скачати файл</ExcelBtn>
            </CSVLink>
          ) : (
            <>
              <RangePicker style={{ marginRight: 16 }} onChange={(e) => setExportRange(e)} format="DD.MM.YYYY" />
              <ExcelBtn style={{ width: 'auto' }} onClick={loadExport}>
                Export
              </ExcelBtn>
            </>
          )}
        </Row>
      )}
      <br />
      <Row style={{ justifyContent: 'center' }}>
        <Radio.Group options={tabs} optionType="button" value={tab} onChange={handleChangeTab} />
      </Row>
      <br />
      <Card className="filtersContainer">
        <Form
          onFinish={handleFilterChange}
          layout="inline"
          initialValues={{ fil: inspector ? '' : userAuth.fil, author: inspector ? '' : userAuth._id }}
        >
          <div>
            <Divider children={'Фільтри:'} style={{ margin: 0 }} orientation="left" />
            <Box>
              <Text>Філія: </Text> &nbsp;
              <Item name="fil" children={<SearchSelectFil disabled={!inspector} />} />
            </Box>
            <Box>
              <Text style={{ paddingRight: '20px' }}>Категорія: </Text> &nbsp;
              <Item
                preserve={true}
                name="category"
                style={{ minWidth: '250px' }}
                children={
                  <SearchSelectCategory
                    value={selectedCategory}
                    onChange={(value) => {
                      form.setFieldsValue({ subCategory: '' });
                      setSelectedSubCategory('');

                      setSelectedCategory(value);
                    }}
                  />
                }
              />
            </Box>
            <Box>
              <Text>Підкатегорія: </Text> &nbsp;
              <Item
                preserve={true}
                name="subCategory"
                style={{ minWidth: '250px' }}
                children={
                  <SearchSelectSubCategory
                    category={selectedCategory}
                    value={selectedSubCategory}
                    onChange={(value) => {
                      setSelectedSubCategory(value);
                      form.setFieldsValue({ subCategory: value });
                    }}
                  />
                }
              />
            </Box>
            <Box>
              <Text>Номер витрати: </Text> &nbsp;
              <Item name="i" children={<InputNumber />} />
            </Box>
            <Box>
              <Text>Період: </Text> &nbsp;
              <Item name="dateRange" children={<RangePicker format="DD.MM.YYYY" />} />
            </Box>

            <Box>
              <Text>Автор: </Text> &nbsp;
              <Item name="author" children={<SearchSelectAuthor isManager={managerOnly} disabled={moreThanManager} />} />
            </Box>

            <Box>
              <Button style={{ width: 150 }} type="primary" htmlType="submit">
                Пошук <SearchOutlined />
              </Button>
            </Box>
          </div>

          <div>
            <Divider children={'Сортування:'} style={{ margin: 0 }} orientation="left" />

            <Box>
              <Text>За номером: </Text> &nbsp;
              <Group value={sortNumber} buttonStyle="solid" size="small" onChange={({ target: { value } }) => handleSortNumberChange(value)}>
                <ButtonR value={0} children={<SmallDashOutlined />} />
                <ButtonR value={-1} children={<SortDescendingOutlined />} />
                <ButtonR value={1} children={<SortAscendingOutlined />} />
              </Group>
            </Box>
            <Box>
              <Text style={{ paddingRight: '20px' }}>За датою: </Text> &nbsp;
              <Group value={sortDate} buttonStyle="solid" size="small" onChange={({ target: { value } }) => handleSortDateChange(value)}>
                <ButtonR value={0} children={<SmallDashOutlined />} />
                <ButtonR value={-1} children={<SortDescendingOutlined />} />
                <ButtonR value={1} children={<SortAscendingOutlined />} />
              </Group>
            </Box>
          </div>
        </Form>
      </Card>
      <br />

      <Spin spinning={loading} tip="Зачекайте" size="large">
        {expenses?.length ? (
          expenses?.map((expense) => <ExpenseInfo key={expense._id} data={expense} />)
        ) : (
          <Empty description={<Text>Нема витрат</Text>} />
        )}
        <Pagination
          style={{ textAlign: 'center' }}
          current={currentPage}
          pageSize={pageSize}
          total={total}
          onChange={handlePageChange}
          showSizeChanger
          onShowSizeChange={handlePageSizeChange}
          pageSizeOptions={PAGE_SIZE_OPTIONS.map((size) => size.toString())}
        />
      </Spin>
    </div>
  );
};

export default Expense;
