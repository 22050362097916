import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'antd';

import { setModal } from '../../store/commonReducer';
import { request, requestFile, error } from '../../tools';
import Chat from '../../components/Chat';

import './styles.scss';

const ChatForHotline = () => {
  const dispatch = useDispatch();
  const modalRef = useRef(null);

  const { data } = useSelector((state) => state.common.modal);
  const userAuth = useSelector((state) => state.common.userAuth);
  const users = useSelector((state) => state.common.users);

  const onSendMessage = (params = {}) => {
    if (!params.message) return error('Пусте повідомлення');
    if (typeof params.callback !== 'function') return error('Не вказана функція callback');

    request.post(
      '/hotline/chatSendMessage',
      {
        documentId: data.hotlineId,
        message: params.message,
      },
      (res) => {
        params.callback(res);
      },
      error,
    );
  };

  const onSendFile = (params = {}) => {
    const { formData, callback, onError } = params;

    if (!formData) return error('Відсутні даги для відправки');
    if (typeof callback !== 'function') return error('Не вказана функція callback');

    formData.append('documentId', data.hotlineId);

    request.post('/hotline/chatSendFile', formData, callback, onError);
  };

  const onGetFile = (params = {}) => {
    const { fileId, fileName, callback, onError } = params;

    if (!fileId) return error('Відсутній ID файлу');
    // if (typeof callback !== 'function') return error('Не вказана функція callback');

    requestFile('/hotline/chatGetFile', { fileId, fileName, documentId: data.hotlineId }, callback, onError);
  };

  const onGetMessages = (callback) => {
    if (typeof callback !== 'function') return error('Не вказана функція callback');

    request.post(
      '/hotline/getchat',
      { documentId: data.hotlineId },
      (req) => {
        const messages =
          req.data?.map((item) => ({
            ...item,
            isSentByCurrentUser: item.sender === userAuth._id,
            name: users[item.sender]?.name || 'Без імені',
          })) ?? [];

        callback({
          messages,
          messagesCount: messages.length,
        });
      },
      error,
    );
  };

  // useEffect to get modal ref
  useEffect(() => {
    const antModalWrap = document.querySelector('.ant-modal-wrap');
    modalRef.current = antModalWrap;
  }, []);

  if (!data.hotlineId) {
    error('Відсутнє ID консультації');
    return null;
  }

  return (
    <Modal open title={data.hotlineTitle} onCancel={() => dispatch(setModal())} footer={null}>
      <Chat
        title={data.hotlineTitle}
        onSendMessage={onSendMessage}
        onSendFile={onSendFile}
        onGetMessages={onGetMessages}
        onGetFile={onGetFile}
        scrollRef={modalRef}
      />
    </Modal>
  );
};

export default ChatForHotline;
