import React, { useState, useEffect, useRef, useMemo } from 'react';
import { CHAT_TYPES } from 'common_constants/business';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'antd';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { request, error } from '../../tools';
import { updateUnreadedMessages, setChatsData, updateChat } from '../../store/commonReducer';
import { tabsItems } from './helpers';
import ChatMessages from './ChatMessages';
import ChatsWrapper from './ChatsWrapper';

import './Chat.scss';

const Chat = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const messagesWrapper = useRef(null);

  const users = useSelector((state) => state.common.users);
  const userAuth = useSelector((state) => state.common.userAuth);
  const FILII = useSelector((state) => state.common.FILII);
  const chatsData = useSelector((state) => state.common.chatsData);
  const contractsChatsFilter = useSelector((state) => state.common.contractsChatsFilter);

  const [currentChatId, setCurrentChatId] = useState(location.state?.id ?? null);
  const [inputSearch, setInputSearch] = useState('');
  const [contractNumberSearch, setContractNumberSearch] = useState();
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [activeTab, setActiveTab] = useState(location.state?.type || tabsItems[0]?.key);
  const [isFetchedChat, setIsFetchedChat] = useState(false);
  const [currentPage, setCurrentPage] = useState({});
  const [totalCount, setTotalCount] = useState({});
  const [isTypeChatsFetching, setIsTypeChatsFetching] = useState(true);
  const [chatUsersLoading, setChatUsersLoading] = useState(false);

  const isLawyerChat = activeTab === CHAT_TYPES.lawyerChat.key;
  const isGroupChat = activeTab === CHAT_TYPES.groupChat.key;
  const isChatWithClient = activeTab === CHAT_TYPES.chatWithClient.key;
  const isContractChat = activeTab === CHAT_TYPES.contractChat.key;
  const redButtonChat = activeTab === CHAT_TYPES.redButtonChat.key;

  const activeUsers = useMemo(() => Object.values(users || {}).filter((user) => !user.fired && user._id !== userAuth?._id), [users]);

  useEffect(() => {
    if (inputSearch?.length === 0) {
      setSearchedUsers(chatsData[activeTab] || []);
      return;
    }
    if (isGroupChat) return;

    const currentUsers =
      activeUsers?.filter(
        (user) => user.p.toLowerCase().includes(inputSearch.toLowerCase()) || FILII?.[user?.fil]?.toLowerCase().includes(inputSearch.toLowerCase()),
      ) || [];

    const currentUsersMapped = currentUsers.map((item) => ({ ...item, type: activeTab }));
    setSearchedUsers(currentUsersMapped);
  }, [inputSearch, chatsData]);

  useEffect(() => {
    if (activeTab !== location.state?.type && currentChatId !== location.state?.id) {
      setCurrentChatId(null);
    }

    getAllChatsByType();
  }, [isTypeChatsFetching, contractsChatsFilter?.[activeTab]]);

  useEffect(() => {
    if (!currentChatId) return;

    if (isFetchedChat) {
      setIsFetchedChat(false);
      return;
    }

    if (!chatsData?.[activeTab]) return;

    const isCurrentChatExist = chatsData?.[activeTab]?.find((item) => item._id === currentChatId);

    if (isCurrentChatExist) return;

    getChat();
  }, [currentChatId]);

  useEffect(() => {
    if ((isChatWithClient || isContractChat || redButtonChat) && !contractNumberSearch) {
      setSearchedUsers(chatsData?.[activeTab]);
    }
  }, [contractNumberSearch]);

  useEffect(() => {
    setCurrentPage((prev) => ({ ...prev, [activeTab]: 1 }));
    setIsTypeChatsFetching(true);
  }, [contractsChatsFilter]);

  function getAllChatsByType() {
    if (!isTypeChatsFetching) return;

    const reqData = {
      type: activeTab,
      page: currentPage?.[activeTab] ?? 1,
    };

    if ((isChatWithClient || isContractChat) && contractsChatsFilter[activeTab].fil) {
      reqData.f = Number(contractsChatsFilter[activeTab].fil);
    }

    setChatUsersLoading(true);

    request.post(
      '/chatPrivate/getAllChatsForUser',
      reqData,
      ({ data, metadata }) => {
        const chatDataIds = data?.map((item) => item._id);
        const updatedTypeChats = [...(chatsData?.[activeTab]?.filter((chatItem) => !chatDataIds?.includes(chatItem?._id)) ?? []), ...data];

        dispatch(setChatsData({ type: activeTab, data: updatedTypeChats }));

        setSearchedUsers(updatedTypeChats);
        setTotalCount((prev) => ({ ...prev, [activeTab]: metadata?.total }));
        setCurrentPage((prev) => ({ ...prev, [activeTab]: 1 + (prev?.activeTab ?? 1) }));
        setIsTypeChatsFetching(false);
        setChatUsersLoading(false);
      },
      () => {
        error();
        setIsTypeChatsFetching(false);
        setChatUsersLoading(false);
        setSearchedUsers(null);
      },
    );
  }

  function setReadedMessages() {
    const requestData = {
      chatId: currentChatId,
      type: activeTab,
    };

    request.post(
      '/chatPrivate/setReadedMessages',
      requestData,
      () => {
        dispatch(updateUnreadedMessages({ chatId: currentChatId, chatType: redButtonChat ? CHAT_TYPES.chatWithClient.key : activeTab }));
      },
      error,
    );
  }

  function getChat() {
    request.post(
      '/chatPrivate/getchat',
      { chatId: currentChatId, type: activeTab },
      ({ data }) => {
        if (!data?._id) return;

        dispatch(updateChat({ chatType: activeTab, data: data }));
        dispatch(updateUnreadedMessages({ chatId: currentChatId, chatType: redButtonChat ? CHAT_TYPES.chatWithClient.key : activeTab }));
      },
      error,
    );
  }

  function onChangeCurrentChatId(id) {
    setCurrentChatId(id);
  }

  return (
    <section className="chat-page">
      <Row className="wrapper">
        <ChatsWrapper
          activeTab={activeTab}
          setInputSearch={setInputSearch}
          setContractNumberSearch={setContractNumberSearch}
          contractNumberSearch={contractNumberSearch}
          searchedUsers={searchedUsers}
          setActiveTab={setActiveTab}
          chatUsersLoading={chatUsersLoading}
          setSearchedUsers={setSearchedUsers}
          inputSearch={inputSearch}
          totalCount={totalCount}
          onChangeCurrentChatId={onChangeCurrentChatId}
          currentChatId={currentChatId}
          setCurrentPage={setCurrentPage}
          setIsTypeChatsFetching={setIsTypeChatsFetching}
          setIsFetchedChat={setIsFetchedChat}
        />

        <Col ref={messagesWrapper} md={{ span: 16 }} className={clsx('messages', (isChatWithClient || redButtonChat) && '_client')}>
          <ChatMessages
            onChangeCurrentChatId={onChangeCurrentChatId}
            chatType={activeTab}
            currentChatId={currentChatId}
            setReadedMessages={setReadedMessages}
          />
        </Col>
      </Row>
    </section>
  );
};

export default Chat;
