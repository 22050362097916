import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CHAT_TYPES } from 'common_constants/business';
import { DeleteOutlined, EditOutlined, DownloadOutlined, PushpinOutlined, LoadingOutlined } from '@ant-design/icons';
import { AutoSizer } from 'react-virtualized';
import { VariableSizeList as List } from 'react-window';
import clsx from 'clsx';

import { request, error } from '../../tools';
import ContextMenu from '../ContextMenu';
import MessageRow from './MessageRow';
import { addPinnedMessage } from '../../store/commonReducer';

const MessagesList = ({
  chatId,
  chatType,
  messages,
  messagesCount,
  setMessagesLoader,
  onGetFile,
  handleEditMode,
  isMoreMessagesFetching,
  setIsMoreMessagesFetching,
  prevMessagesCount,
  fetchedMessageIndex,
  pinnedMessageScrollId,
}) => {
  const dispatch = useDispatch();
  const messagesWrapperRef = useRef(null);
  const contextMenuRef = useRef(null);
  const listRef = useRef();
  const sizeMap = useRef({});

  const [isFirstScroll, setIsFirstScroll] = useState(true);
  const [contextMenu, setContextMenu] = useState({
    position: {
      x: 0,
      y: 0,
    },
    toggled: false,
    messageData: {},
  });
  const [highlightedMessageIndex, setHighlightedMessageIndex] = useState(null);
  const [contextMenuIconsLoadingState, setContextMenuIconsLoadingState] = useState({
    isPinningLoading: false,
    isDownloadingLoading: false,
    isDeletingLoading: false,
  });
  const [initialScroll, setInitialScroll] = useState(true);

  const setSize = useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current.resetAfterIndex(index);
  }, []);

  const getSize = (index) => sizeMap.current[index] || 0;

  const rowCount = useMemo(() => (messagesCount > messages.length ? messages.length + 1 : messages.length), [messages]);
  const hasNext = useMemo(() => messagesCount > messages.length, [messagesCount, messages]);

  const deleteMessage = async (item) => {
    if (!item._id) {
      error('На жаль, дане повідомлення не можна видалити');
    }

    setContextMenuIconsLoadingState((prev) => ({ ...prev, isDeletingLoading: true }));

    const requestData = {
      chatId,
      type: chatType,
      messageId: item._id,
    };

    await request.post(
      '/chatPrivate/deleteMessage',
      requestData,
      () => {
        resetContextMenu();
      },
      error,
    );

    setContextMenuIconsLoadingState((prev) => ({ ...prev, isDeletingLoading: false }));
  };

  const resetContextMenu = () => {
    setContextMenu({
      position: {
        x: 0,
        y: 0,
      },
      toggled: false,
    });
  };

  const handleDownloadFile = (fileId, fileName) => {
    if (!fileId) return;
    setMessagesLoader(true);
    setContextMenuIconsLoadingState((prev) => ({ ...prev, isDownloadingLoading: true }));
    onGetFile({
      fileId,
      fileName,
      callback: () => {
        setMessagesLoader(false);
        setContextMenuIconsLoadingState((prev) => ({ ...prev, isDownloadingLoading: false }));
      },
    });
  };

  const handlePinMessage = async (messageId) => {
    setContextMenuIconsLoadingState((prev) => ({ ...prev, isPinningLoading: true }));

    const requestBody = {
      messageId,
      chatId,
      chatType,
    };

    await request.post(
      '/chatPrivate/pinMessage',
      requestBody,
      ({ pinnedMessages }) => {
        dispatch(addPinnedMessage({ chatId, chatType, pinnedMessages }));
        setContextMenu((prev) => ({ ...prev, toggled: false }));
      },
      error,
    );

    setContextMenuIconsLoadingState((prev) => ({ ...prev, isPinningLoading: false }));
  };

  const handleListScroll = (e) => {
    if (!hasNext) return;

    if (e.scrollOffset < 150 && !isMoreMessagesFetching && !isFirstScroll) {
      setIsMoreMessagesFetching(true);
    }

    setIsFirstScroll(false);
  };

  const handleOnContextMenu = (e, item) => {
    e.preventDefault();

    const contextMenuArr = contextMenuRef.current.getBoundingClientRect();

    const isLeft = e.clientX < window?.clientX / 2;

    let x;
    let y = e.clientY;

    if (isLeft) {
      x = e.clientX;
    } else {
      x = e.clientX - contextMenuArr?.width;
    }

    setContextMenu({
      position: {
        x: x,
        y: y,
      },
      toggled: true,
      messageData: item,
    });
  };

  const onClickHandleEditMode = (item) => {
    handleEditMode(item);
    resetContextMenu();
  };

  useEffect(() => {
    function handler(e) {
      if (contextMenuRef.current) {
        if (!contextMenuRef.current.contains(e.target)) {
          resetContextMenu();
        }
      }
    }

    document.addEventListener('click', handler);

    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  useEffect(() => {
    setHighlightedMessageIndex(fetchedMessageIndex);

    const timeout = setTimeout(() => {
      setHighlightedMessageIndex(null);
    }, 1500);

    return () => clearTimeout(timeout);
  }, [fetchedMessageIndex]);

  useEffect(() => {
    if (listRef.current && messages?.length - prevMessagesCount >= 0) {
      listRef.current.scrollToItem(messages?.length - prevMessagesCount);
    }
  }, [messages?.length]);

  useEffect(() => {
    if (listRef.current && fetchedMessageIndex) {
      listRef.current.scrollToItem(fetchedMessageIndex);
    }
  }, [fetchedMessageIndex]);

  useEffect(() => {
    if (!pinnedMessageScrollId) return;

    let messageIndex = messages.findIndex((message) => String(message._id) === String(pinnedMessageScrollId));

    if (messageIndex || messageIndex === 0) {
      listRef.current.scrollToItem(hasNext ? messageIndex + 1 : messageIndex);
      setHighlightedMessageIndex(hasNext ? messageIndex + 1 : messageIndex);

      const timeout = setTimeout(() => {
        setHighlightedMessageIndex(null);
      }, 1000);

      return () => clearTimeout(timeout);
    } else {
      listRef.current.scrollToItem(0);
    }
  }, [pinnedMessageScrollId]);

  return (
    <div ref={messagesWrapperRef} className="messages-wrapper" style={{ width: '100%', height: '100%' }}>
      <AutoSizer>
        {({ height, width }) => (
          <List ref={listRef} height={height} width={width} itemCount={rowCount ?? 1} itemSize={getSize} onScroll={handleListScroll}>
            {({ index, style }) => {
              return (
                <div className={clsx('message-wrapper', index === highlightedMessageIndex ? 'highlight' : '')} style={style}>
                  <MessageRow
                    data={messages}
                    index={index}
                    setSize={setSize}
                    handleOnContextMenu={handleOnContextMenu}
                    hasNext={hasNext}
                    handleDownloadFile={handleDownloadFile}
                  />
                </div>
              );
            }}
          </List>
        )}
      </AutoSizer>
      <ContextMenu
        contextMenuRef={contextMenuRef}
        positionX={contextMenu.position.x}
        positionY={contextMenu.position.y}
        isToggled={contextMenu.toggled}
        rightClickItem={contextMenu.messageData}
        buttons={[
          ...(chatType !== CHAT_TYPES.chatWithClient.key && chatType !== CHAT_TYPES.redButtonChat.key
            ? [
                {
                  text: contextMenu.messageData?.pinned ? 'Відкріпити' : 'Закріпити',
                  icon: contextMenuIconsLoadingState.isPinningLoading ? LoadingOutlined : PushpinOutlined,
                  onClick: (e, item) => handlePinMessage(item._id),
                },
              ]
            : []),
          ...(contextMenu.messageData?.fileId
            ? [
                {
                  text: 'Завантажити',
                  icon: contextMenuIconsLoadingState.isDownloadingLoading ? LoadingOutlined : DownloadOutlined,
                  onClick: (e, item) => handleDownloadFile(item.fileId, item.fileName ?? 'Файл'),
                },
              ]
            : []),
          ...(contextMenu.messageData?.isSentByCurrentUser && contextMenu.messageData?.message
            ? [
                {
                  text: 'Редагувати',
                  icon: EditOutlined,
                  onClick: (e, item) => onClickHandleEditMode(item),
                },
              ]
            : []),
          ...(contextMenu.messageData?.isSentByCurrentUser
            ? [
                {
                  text: 'Видалити',
                  icon: contextMenuIconsLoadingState.isDeletingLoading ? LoadingOutlined : DeleteOutlined,
                  onClick: (e, item) => deleteMessage(item),
                },
              ]
            : []),
        ]}
      />
    </div>
  );
};

export default MessagesList;
