import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Button, Spin, Modal, DatePicker, Steps } from 'antd';
import { CSVLink } from 'react-csv';
import locale from 'antd/locale/uk_UA';

import { setModal } from '../../store/commonReducer';
import { request, error } from '../../tools';
import Excel from '../../images/excel_logo.svg';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import {
  PRAVO,
  HOTLINE_STATUS,
  HOTLINE_ADV_WHERE,
  HOTLINE_RESULT,
  HOTLINE_ADV_WHERE_MORE,
  HOBBIES,
  JOBS,
  POSLUGI,
  SOC_STATUS,
  WORK_STATES,
  TOWNS,
  HOTLINE_CALLBACK_STATUS,
  PRAVO_TYPES,
  FORM_OF_BUSINESS,
  SERVIS_OPTIONS,
  REPEATS,
  PAYMENT_FORMAT,
} from 'common_constants/business';
import { populateCalls, populateHotlineCalls } from './helpers';

const fields = ['status', 'refusalReason', 'pravoType', 'clientName', 'se', 'state'];

const hotlineTitles = [
  'Філія',
  'Послуга',
  'Дата',
  "Ім'я",
  'Час',
  'Вид',
  'Сума',
  'Коментар',
  'Автор',
  'Створено',
  'Завершеність',
  'Категорія анкетування',
  'Що саме',
  'Призначено',
  // 'Анкетування',
  'Названа ціна',
  'Результат зустрічі',
];

const clientTitles = [
  'ПІБ',
  'ДН',
  'Місто народження',
  'Стать',
  'Адреса',
  // 'Телефон',
  'E-mail',
  'Професія',
  'Соц статус',
  'Службове становище',
  'Хоббі',
  // 'Звязок 1',
  // 'телефон',
  // 'Звязок 2',
  // 'телефон',
  // 'Звязок 3',
  // 'телефон',
  // 'інтелект',
  // 'критичність мислення',
  // 'ініціативність',
  // 'поступливість',
  // 'дружелюбність',
  // 'емоційність',
];

const contractTitles = [
  '№ Договору',
  'Дата дог',
  'Філія Договору',
  'Уклав',
  'Хто буде обслуговувати?',
  'Офіс/дист',
  // 'Повторно',
  'Як дізнався вид р',
  'Що саме',
  'Дата 1п',
  'Сума 1п',
  'ФО/ФОП/ЮО',
  // 'ОВ 1',
  // 'ОВ 2',
  // 'ОВ 3',
  // 'ДН',
  // 'Місто народження',
  // 'Стать',
  // 'Адреса',
  // 'Телефон',
  // 'E-mail',
  // '№ ККАОЗ',
  'Вид правовідносин',
  'Що саме',
  'Предмет договору',
  'Своїми словами про справу',
  'Форма гонорара',
  'Гонорар',
  'Кільк міс',
  'Вихід адв',
  'Оплатив',
];

const csvDataTitles = [
  'Дзвінок з',
  // 'Дзвінок на',
  // 'Час очікування',
  // 'Час відповіді',
  'Тривалість',
  'Дата Початку',
  'Початок',
  'Право дзвінка',
  'Звітував',
  'Статус',
  'Відосток заповнення',

  ...hotlineTitles,
  ...clientTitles,
  ...contractTitles,
];

const ExportHotlineCalls = () => {
  const dispatch = useDispatch();
  const FILII = useSelector((state) => state.common.FILII || []);

  const { RangePicker } = DatePicker;

  const users = useSelector((state) => state.common.users);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([csvDataTitles]);
  const [generate, setGenerate] = useState(true);
  const [dateArr, setDateArr] = useState();

  const [step, setStep] = useState(0);

  const onGenerate = async () => {
    setLoading(true);
    setGenerate(false);

    setStep(0);
    let calls;
    let hotlines;
    let clients;
    let contracts;

    // fetching data
    await request.post(
      '/spreadsheets/exportHotlineCalls/calls',
      {
        exportRange: dateArr,
      },
      (res) => {
        setStep(1);
        calls = res.data;
      },
      error,
    );
    const callsPhones = calls.map((call) => `0${call.from}`);

    await request.post(
      '/spreadsheets/exportHotlineCalls/hotlines',
      {
        exportRange: dateArr,
      },
      (res) => {
        setStep(2);
        hotlines = res.data;
      },
      error,
    );
    const holtinePhones = hotlines.map((hotline) => hotline.phone);

    await request.post(
      '/spreadsheets/exportHotlineCalls/clients',
      {
        phones: [...new Set([...callsPhones, ...holtinePhones])],
      },
      (res) => {
        setStep(3);
        clients = res.data;
      },
      error,
    );
    const contractIdArray = [...new Set(hotlines.map((hotline) => hotline.K))];

    await request.post(
      '/spreadsheets/exportHotlineCalls/contracts',
      {
        contractIdArray,
      },
      (res) => {
        contracts = res.data;
      },
      error,
    );

    // grouping data
    const populatedHotlineCalls = populateHotlineCalls(hotlines, calls, clients, contracts);

    const callIdArray = [...new Set(hotlines.map((hotline) => hotline.call_id))].filter((id) => id !== undefined && id !== null);
    const noHotlineCalls = calls.filter((call) => !callIdArray.includes(String(call.callId)));

    const populatedCalls = populateCalls(noHotlineCalls, clients);

    populatedHotlineCalls.push(...populatedCalls);
    populatedHotlineCalls.sort((a, b) => ((a.hotline?.crAt ?? a?.start_time) > (b.hotline?.crAt ?? b?.start_time) ? 1 : -1));

    setStep(4);
    setLoading(false);

    // creating csv
    const cvsData = [csvDataTitles];
    populatedHotlineCalls?.forEach((call) => {
      const client = call.client || {};
      const hotline = call.hotline || {};
      const contract = call.contract || {};

      let feeForm,
        paid,
        feeSum = contract?.pa;

      if (contract.monthlyFee && contract?.fp >= contract?.pa) {
        feeForm = PAYMENT_FORMAT[3];
        paid = `${contract.fp}/${contract.pa}`;
        feeSum = contract?.monthlyFee;
      }
      if (contract.monthlyFee && Number(contract?.fp) < Number(contract?.pa)) {
        feeForm = PAYMENT_FORMAT[4];
        paid = `${contract?.fp || 0}/${contract?.installmentMonths || 0}/${contract.pa}`;
        feeSum = contract?.monthlyFee;
      }
      if (contract?.fp >= contract?.pa && !contract.monthlyFee) {
        feeForm = PAYMENT_FORMAT[0];
        paid = contract?.fp;
        feeSum = contract?.fp;
      }
      if (Number(contract?.fp) < Number(contract?.pa) && !contract.monthlyFee) {
        feeForm = PAYMENT_FORMAT[1];
        paid = `${contract?.fp || 0}/${contract?.installmentMonths || 0}/${contract.pa}`;
      }
      if (contract?.pm && !Number(contract?.pa)) {
        if (!Number(contract?.fp) && !Number(contract?.monthlyFee) && !Number(contract?.installmentMonths)) {
          feeForm = PAYMENT_FORMAT[0];
          paid = contract.fp;
        } else {
          feeForm = PAYMENT_FORMAT[2];
          paid = contract?.fp;
          feeSum = contract?.monthlyFee;
        }
      }

      const d = dayjs(call.start_time ?? hotline.crAt);

      const address = `${client?.a ? client?.a + ',' : ''} ${client?.pt === 1 ? 'паспорт' : client?.pt === 0 ? 'ID-картка' : ''} ${
        client?.pn ? client?.pn + ',' : ''
      } ${client?.pkv ? 'виданий ' + client?.pkv : ''} ${client?.pdv ? 'від ' + dayjs(client?.pdv)?.format('DD-MM-YYYY') + ',' : ''} ${
        client?.inn ? 'ІПН ' + client?.inn : ''
      }`;

      cvsData.push([
        call.from || hotline.phone,
        // call.to,
        // call.waiting_seconds,
        // call.answer_seconds,
        call.total_seconds,
        d.format('DD-MM-YYYY'),
        d.format('HH:mm:ss'),
        PRAVO[call.pravoType] ?? '-',
        users[call.reporter]?.p ?? '-',
        call.status === 1 ? 'Запис' : HOTLINE_CALLBACK_STATUS.find((s) => s.value === call.status)?.label,
        call.reportPercent,

        // hotline part
        FILII?.[hotline.fil] ?? '-', // * 'Філія',
        POSLUGI[hotline.type] ?? '-', // * 'Послуга',
        hotline.date ? dayjs(hotline.date)?.format('DD.MM.YYYY') : '-', // * 'Дата',
        hotline.name ?? '-', // * "Ім'я",
        (hotline.startT ?? '-') + '-' + (hotline.endT ?? '-'), // * 'Час',
        // hotline.phone ?? '-', // * 'Телефон',
        PRAVO[hotline.pravo] ?? '-', // * 'Вид',
        hotline.cost ?? '-', // * 'Сума',
        hotline.comment?.replaceAll(`'`, '')?.replaceAll(`"`, '') ?? '-', // * 'Коментар',
        (window.users && window.users[hotline.author]?.p) ?? '-', // * 'Автор',
        hotline.crAt ? dayjs(hotline.crAt)?.format('DD.MM.YYYY HH:mm:ss') : '-', // * 'Створено',
        HOTLINE_STATUS[hotline.s] ?? '-', // * 'Завершеність',
        HOTLINE_ADV_WHERE[hotline.aw] ?? '-', // * 'Категорія анкетування', (Як дізнався / вид р)
        HOTLINE_ADV_WHERE_MORE[hotline.am] ?? '-', // * 'Що саме',
        (window.users && window.users[hotline.amm]?.p) ?? users[hotline.amm]?.p ?? '-', // * 'Призначено',
        // 'AUTO', // * 'Анкетування',
        hotline.amp ?? '-', // * 'Названа ціна',
        HOTLINE_RESULT[hotline.amr] ?? '-', // * 'Результат зустрічі',

        // client part
        client?.n ?? '',
        client?.b ? (client?.b?.length > 10 ? dayjs(client?.b).format('DD.MM.YYYY') : dayjs(client?.b, 'DD-MM-YYYY').format('DD.MM.YYYY')) : '',
        TOWNS[client?.t] ?? '',
        client?.se === 1 ? 'ч' : client?.se === 0 ? 'ж' : '',
        address,
        // client?.ph ?? '',
        client?.email ?? '',
        JOBS[client?.j] ?? '',
        SOC_STATUS[client?.ss] ?? '',
        WORK_STATES[client?.ws] ?? '',
        HOBBIES[client?.h] ?? '',
        // client?.r?.[0]?.name ?? '',
        // client?.r?.[0]?.phone ?? '',
        // client?.r?.[1]?.name ?? '',
        // client?.r?.[1]?.phone ?? '',
        // client?.r?.[2]?.name ?? '',
        // client?.r?.[2]?.phone ?? '',
        // client?.ch1 ?? '',
        // client?.ch2 ?? '',
        // client?.ch3 ?? '',
        // client?.ch4 ?? '',
        // client?.ch5 ?? '',
        // client?.ch6 ?? '',

        // contract part
        contract.i ?? '',
        contract.sd ? dayjs(contract.sd)?.format('DD.MM.YYYY') : '',
        FILII[contract.f] ?? '',
        users[contract.us]?.p ?? '',
        SERVIS_OPTIONS[contract.un] ?? '',
        contract.od === 1 ? 'Дист' : contract.od === 0 ? 'Офіс' : '',
        // REPEATS[contract.tm] ?? '',
        HOTLINE_ADV_WHERE[contract.aw] ?? '',
        HOTLINE_ADV_WHERE_MORE[contract.am] ?? '',
        contract.md ? dayjs(contract.md)?.format('DD.MM.YYYY') : '',
        contract.fp ?? '',
        FORM_OF_BUSINESS[client?.u] ?? '',
        // contract.ov1 ?? '',
        // contract.ov2 ?? '',
        // contract.ov3 ?? '',
        // birthday
        // dayjs(client?.b).format("DD-MM-YYYY"),
        // client?.b ? (client?.b?.length > 10 ? dayjs(client?.b).format('DD.MM.YYYY') : dayjs(client?.b, 'DD-MM-YYYY').format('DD.MM.YYYY')) : '',
        // TOWNS[client?.t] ?? '',
        // client?.se === 1 ? 'ч' : 'ж',
        // address,
        // client?.ph ?? '',
        // client?.email ?? '',
        // contract.kk ?? '',
        PRAVO_TYPES[contract.pr] ?? '',
        PRAVO[contract.prc] ?? '',
        contract.ci?.replaceAll(`'`, '')?.replaceAll(`"`, '') ?? '',
        contract.cd?.replaceAll(`'`, '')?.replaceAll(`"`, '') ?? '',
        feeForm ?? '',
        Number(feeSum) ? feeSum : '',
        contract.monthlyFee ? '∞' : '',
        contract.ea ?? '',
        paid ?? '',
      ]);
    });
    setData(cvsData);
    setGenerate(false);
  };

  return (
    <Modal open className="export-hotline" title="Експорт дзвінків" onCancel={() => dispatch(setModal())} footer={null}>
      <Card className="change">
        <RangePicker
          disabled={loading}
          locale={locale}
          className="exp-filter"
          onChange={(value) => setDateArr(value ? [new Date(value?.[0]), new Date(value?.[1])] : '_')}
        />
        <br />
        {generate ? (
          <Button onClick={onGenerate}>Генерувати</Button>
        ) : (
          <>
            <Steps
              current={step}
              size="small"
              direction="vertical"
              items={[
                {
                  title: 'Завантажуємо дзвінки',
                },
                {
                  title: 'Завантажуємо записи',
                },
                {
                  title: 'Завантажуємо клієнтів',
                },
                {
                  title: 'Завантажуємо договори',
                },
              ]}
            />
            <div
              style={{
                opacity: loading ? 0.5 : 1,
                pointerEvents: loading ? 'none' : 'auto',
              }}
            >
              <CSVLink filename={'hotline-calls.csv'} asyncOnClick={true} data={data}>
                <Button className="excel">
                  <img className="excel-img" src={Excel} alt="Excel" /> Завантажити hotline-calls.csv
                </Button>
              </CSVLink>
            </div>
          </>
        )}
      </Card>
    </Modal>
  );
};

export default ExportHotlineCalls;
