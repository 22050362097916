import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Row, Spin, Empty } from 'antd';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { PRAVO, ROLES } from 'common_constants/business';
import { ROUTES } from 'common_constants/routes';
import RatingCard from '../Rating/RatingPartials/RatingCard/RatingCard';
import BalanceWidget from '../../components/BalanceWidget/BalanceWidget';
import { setMainLoader } from '../../store/uiReducer';
import {
  setContractsRating,
  setReachesRating,
  setCurrentMonthContractsRating,
  setCurrentMonthReachesRating,
  setAutoRotateReachesState,
} from '../../store/commonReducer';
import { request, error } from '../../tools';

import './HomeDashboard.scss';
import BirthTable from '../../components/BirthTable/BirthTable';
import AssignmentsTable from '../../components/AssignmentsTable/AssignmentsTable';

const HomeDashboard = (props) => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.common.userAuth);
  const currentMonthContractsRating = useSelector((state) => state.common.currentMonthContractsRating);
  const currentMonthReachesRating = useSelector((state) => state.common.currentMonthReachesRating);
  const currentUserContarctsRating = currentMonthContractsRating?.find((ratingItem) => ratingItem.userId === userAuth._id);
  const currentUserReachesRating = currentMonthReachesRating?.find((ratingItem) => ratingItem.userId === userAuth._id);

  const rabfil = ROLES[userAuth.role] <= ROLES.rabfil;

  const [today, setToday] = useState([]);
  const [loading, setLoading] = useState(true);

  const getList = () => {
    const transaction = {
      bmm: userAuth._id,
    };

    setLoading(true);

    request.post(
      '/hotline/getClientCurrentHotlines',
      transaction,
      (req) => {
        setToday(req.data);
        setLoading(false);
      },
      error,
    );
  };

  useEffect(() => {
    getList();
  }, []);

  // useEffect(() => {
  //   if (!currentMonthContractsRating && !currentMonthReachesRating) {
  //     dispatch(setMainLoader(true));

  //     request.post(
  //       '/rating/get',
  //       {},
  //       ({ contractsRating, reachesRating, autoRotateReachesState, currentMonthContractsRating, currentMonthReachesRating }) => {
  //         dispatch(setContractsRating(contractsRating));
  //         dispatch(setCurrentMonthContractsRating(currentMonthContractsRating));
  //         dispatch(setReachesRating(reachesRating));
  //         dispatch(setCurrentMonthReachesRating(currentMonthReachesRating));
  //         dispatch(setAutoRotateReachesState(autoRotateReachesState));

  //         dispatch(setMainLoader(false));
  //       },
  //     );
  //   }
  // }, []);

  const content = today.length ? <View today={today} /> : null;
  const spinner = <Spin tip="Завантаження" spinning={loading} />;
  const empty =
    !today.length && !loading ? (
      <Empty
        description={
          <>
            Немає консультацій на сьогодні
            <br />
            Призначте себе на сторінці <Link to={ROUTES.LIST}>Записи</Link> як консультанта
          </>
        }
      />
    ) : null;

  return (
    <>
      <div className="homeDashboard_page">
        <br />
        <span>Ви зайшли під логіном: {props.username}</span>
        <br />
        <span>Якщо це не Ваш логін, негайно зв'яжіться з Олександром Вікторовичем</span>
        <br />
        <br />
      </div>
      <div className="dashboard-page">
        <Row className="rating-cards">
          <RatingCard type={'contracts'} data={currentUserContarctsRating} />
          <RatingCard type={'hotlineReach'} data={currentUserReachesRating} />
        </Row>
        <br />
        <br />

        {rabfil && <BalanceWidget />}

        <br />
        <br />
        <BirthTable />

        <br />
        <br />
        {rabfil && <AssignmentsTable />}

        <h3 className="dashboard-page dashboard-page-h2">Консультації на сьогодні:</h3>
        {content}
        {spinner}
        {empty}
      </div>
    </>
  );
};

const View = ({ today }) => {
  const [isSingleColumn, setIsSingleColumn] = useState(window.innerWidth < 768);

  const handleResize = () => {
    setIsSingleColumn(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const currentTime = dayjs().format('HH:mm');
  const next = today.find((item) => item.startT > currentTime);

  const filteredToday = today.filter((i) => !i.disabled);

  return (
    <>
      {filteredToday.map((i, index) => {
        return (
          <Card
            key={index}
            className={clsx({
              card: true,
              two_columns: !isSingleColumn,
              disable: i.disabled,
              edited: i.e,
            })}
            style={{
              marginBottom: '20px',
              border: currentTime < i.startT && i._id === next?._id ? '1px solid black' : 'none',
              opacity: currentTime < i.startT ? '1' : '.4',
              display: 'flex',
              flexDirection: isSingleColumn ? 'column' : 'row',
            }}
          >
            {isSingleColumn ? (
              <div style={{ textAlign: 'center' }}>
                <b>Клієнт:</b> {i.name ?? '-'}
                <br />
                <b>Телефон:</b> {i.phone ?? '-'}
                <br />
                <b>Час:</b> {i.startT ?? '-'} - {i.endT ?? '-'}
                <br />
                <b>Вид:</b> {PRAVO[i.pravo] ?? '-'}
                <br />
                <b>Названа ціна:</b> {i.amp ?? '-'}
                <br />
                <b>Коментар:</b> {i.comment ?? '-'}
                {i.e && (
                  <>
                    {' '}
                    <br />
                    <br />
                    <div style={{ color: 'orange' }}>Запис відредаговано</div>
                  </>
                )}
              </div>
            ) : (
              <>
                <div className="row">
                  <b>Клієнт:</b> {i.name ?? '-'}
                </div>
                <div className="row">
                  <b>Телефон:</b> {i.phone ?? '-'}
                </div>
                <div className="row">
                  <b>Час:</b> {i.startT ?? '-'} - {i.endT ?? '-'}
                </div>
                <div className="row">
                  <b>Вид:</b> {PRAVO[i.pravo] ?? '-'}
                </div>
                <div className="row">
                  <b>Названа ціна:</b> {i.amp ?? '-'}
                </div>
                <div className="row full">
                  <b>Коментар:</b> {i.comment ?? '-'}
                </div>
                {i.e && <div style={{ color: 'orange' }}>Запис відредаговано</div>}
              </>
            )}
          </Card>
        );
      })}
    </>
  );
};

export default HomeDashboard;
