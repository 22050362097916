import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';

import { green } from '@ant-design/colors';
import { Button, Input, Select, Calendar } from 'antd';
import { HotlineTimePicker } from 'common_components';
import { ROLES, PSYCHOLOGIST_WORK_TIME } from 'common_constants/business';
import { PSYCHOLOGIST_HOTLINE_SUBMIT } from 'common_constants/modals';
import { translation } from 'common_constants/translation';

import { onTimeClear, onTimeClick } from 'common_components/src/HotlineStep1/Step1/timeUtils';

import { setMainLoader } from '../../store/uiReducer';
import { error, request, warn } from '../../tools';

import { setModal, setCurrentPsychologistHotlineCalendar } from '../../store/commonReducer';
import PageInfo from '../../components/PageInfo';
import ListPsychologistHotline from '../ListPsychologistHotline/ListPsychologistHotline';

import './PsychologistHotline.scss';

const { Option } = Select;
const lang = 'ua';
dayjs.extend(utc);
dayjs.extend(isoWeek);

const POSLUGI = {
  2: '15 хв.',
  3: '30 хв.',
  4: '45 хв.',
  5: '1 год.',
  6: '1 год. 15 хв.',
  7: '1 год. 30 хв.',
  8: '1 год. 45 хв.',
  9: '2 год.',
  10: '2 год. 15 хв.',
  11: '2 год. 30 хв.',
};
const daysOfWeek = ['Понеділок', 'Вівторок', 'Середа', 'Четвер', 'П’ятниця', 'Субота'];

const PsychologistHotline = (props) => {
  const dispatch = useDispatch();

  const [user, users] = [useSelector((state) => state.common.userAuth || {}), useSelector((state) => state.common.users)];
  const psychologist = Object.values(users).find(({ role }) => role === 'psychologist');

  const list = useSelector((state) => state.common.currentPsychologistHotlineCalendar);

  const [currentUser, setCurrentUser] = useState(user);
  const [currentDateIndex, setCurrentDateIndex] = useState(1);
  const [blockedArr, setBlockedArr] = useState(Array.from({ length: 6 }, () => []));
  const [blockedArrInfo, setBlockedArrInfo] = useState(Array.from({ length: 6 }, () => []));
  const [startT, setStartT] = useState({ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' });
  const [endT1, setEndT1] = useState({ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' });
  const [endT2, setEndT2] = useState({ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' });
  const [type, setType] = useState('4');
  const [phone, setPhone] = useState(currentUser.userPersonalData?.workPhone);
  const [selectedDate, setSelectedDate] = useState({
    startOfWeek: dayjs().utc().startOf('isoWeek'),
    endOfWeek: dayjs().utc().endOf('isoWeek'),
  });
  const endT = endT2 || endT1;
  const psychologistOnly = ROLES[user.role] === ROLES.psychologist;

  const [data, setData] = useState({
    clientId: currentUser._id,
    psychologistId: psychologist?._id,
    psychologistName: psychologist?.name,
    psychologisPhone: psychologist?.userPersonalData?.workPhone,
    clientName: currentUser.name,
    clientPhone: phone,
    endT: endT1[currentDateIndex],
    startT: startT[currentDateIndex],
    type: 'Консультація психолога',
    fil: currentUser?.fil,
  });

  const onSelect = (date) => {
    const startOfWeek = date.startOf('week');
    const endOfWeek = date.endOf('week');
    setSelectedDate({ startOfWeek, endOfWeek });
  };
  const handleUserSelect = (value) => {
    const selectedUser = Object.values(users).find(({ _id }) => _id === value);
    setCurrentUser(selectedUser);
    selectedUser.userPersonalData?.workPhone && setPhone(selectedUser.userPersonalData?.workPhone);
    setData((prevState) => ({
      ...prevState,
      clientId: selectedUser._id,
      clientName: selectedUser.name,
      fil: selectedUser?.fil,
      clientPhone: selectedUser.userPersonalData?.workPhone,
    }));
  };
  const weekFormat = (dateRange) => {
    return `Тиждень з ${dateRange.startOfWeek.format('DD.MM.YYYY')} до ${dateRange.endOfWeek.format('DD.MM.YYYY')}`;
  };

  const handleChange = (value) => {
    setType(value);
  };

  const handleChangePhone = (value) => {
    setData((prevState) => ({ ...prevState, clientPhone: value }));
    setPhone(value);
  };

  const handleTimeClick = (e, { dayIndex }) => {
    setCurrentDateIndex(dayIndex);
    const index = PSYCHOLOGIST_WORK_TIME.indexOf(e.target.innerText);
    const arrayHotlineTime = Array.from({ length: type }, (_, i) => index + i);

    const hasIntersection = arrayHotlineTime.some((value) => blockedArr[dayIndex].includes(value));

    if (hasIntersection) {
      warn('', 'Цей час уже зайнято');
      return;
    }

    if (index + parseInt(type) > PSYCHOLOGIST_WORK_TIME.length) {
      warn('', 'Мінімальний час прийому — 1 година. Ви обрали занадто пізній час.');
      return;
    }

    onTimeClick(startT, endT, endT1, endT2, setEndT1, setEndT2, setStartT, e.target, type, psychologist, dayIndex);
  };

  const disabledDate = (current) => {
    return current && current < dayjs().startOf('day').utc();
  };

  const handleClickClearTime = () => onTimeClear(setStartT, setEndT1, setEndT2);

  const onClickEditHandle = () => {
    const nonEmptyKey = Object.entries(startT).find(([key, value]) => value)?.[0];
    const currentDate = dayjs(selectedDate.startOfWeek).utc().add(parseInt(nonEmptyKey), 'day');

    if (psychologistOnly && data.clientId === user._id) {
      warn('', 'Психолог не може записатися до себе на прийом');
      return;
    }

    const toDate = dayjs(currentDate).utc().toISOString();
    dispatch(
      setModal({
        name: PSYCHOLOGIST_HOTLINE_SUBMIT,
        data: { ...data, date: toDate, endT: endT1[currentDateIndex], startT: startT[currentDateIndex] },
      }),
    );
  };

  useEffect(() => {
    setData((prevState) => ({ ...prevState, startT: startT, endT: endT }));
  }, [endT, startT]);

  const updatedpsychologistHotlineCalendar = () => {
    const requestData = {
      startDate: selectedDate.startOfWeek,
      endDate: selectedDate.endOfWeek,
      calendar: true,
    };

    const onSuccess = (req) => {
      dispatch(setCurrentPsychologistHotlineCalendar(req.data));
    };

    const onError = (_, __, { response }) => {
      const hotlineError = response?.data?.error;
      if (hotlineError) {
        error('Помилка!', hotlineError);
      }
      dispatch(setMainLoader(false));
    };

    request.post('/psychologistHotline/get', requestData, onSuccess, onError);
  };
  useEffect(() => {
    updatedpsychologistHotlineCalendar(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    const blockedIndicesArrayByDay = [];
    const blockedIndicesObjectByDay = [];

    list?.forEach((dayArray) => {
      const dayIndicesArray = [];
      const dayIndicesObject = {};

      dayArray.forEach((h) => {
        const startIndex = PSYCHOLOGIST_WORK_TIME.indexOf(h.startT);
        const endIndex = PSYCHOLOGIST_WORK_TIME.indexOf(h.endT);

        if (startIndex !== -1 && endIndex !== -1 && startIndex <= endIndex) {
          dayIndicesArray.push(...Array.from({ length: endIndex - startIndex + 1 }, (_, i) => startIndex + i));
          for (let i = startIndex; i <= endIndex; i++) {
            dayIndicesObject[i] = h;
          }
        }
      });

      blockedIndicesArrayByDay.push(dayIndicesArray);
      blockedIndicesObjectByDay.push(dayIndicesObject);
    });

    setBlockedArr(blockedIndicesArrayByDay);
    setBlockedArrInfo(blockedIndicesObjectByDay);
  }, [list]);

  return (
    <div className="hotline_page default_page">
      <h2 style={{ marginBottom: '40px' }}>
        Запис до психолога <PageInfo />
      </h2>
      <div className="info-wrapper">
        <div className="info">
          {psychologistOnly ? (
            <label>
              ПІБ
              <Select
                showSearch
                value={currentUser.name}
                onChange={handleUserSelect}
                className="Select"
                style={{ width: '100%' }}
                placeholder="Введіть ім'я"
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
              >
                {Object.values(users).map((user) => (
                  <Option key={user._id} value={user._id}>
                    {user.name}
                  </Option>
                ))}
              </Select>
            </label>
          ) : (
            <label>
              ПІБ
              <Input value={currentUser.name} className="Select" disabled />
            </label>
          )}

          <label>
            Телефон
            <Select
              className="Select"
              style={{ width: '100%' }}
              value={phone}
              onChange={handleChangePhone}
              mode="combobox"
              placeholder="Оберіть номер"
            >
              <Option key={currentUser.userPersonalData?.workPhone}>{currentUser.userPersonalData?.workPhone}</Option>
              <Option key={currentUser.userPersonalData?.personalPhone}>{currentUser.userPersonalData?.personalPhone}</Option>
            </Select>
          </label>
          <label>
            Тривалість консультації
            <Select
              className="Select"
              disabled={!psychologistOnly}
              style={{ width: '100%' }}
              value={type}
              onChange={handleChange}
              placeholder="Оберіть тривалість"
            >
              {Object.keys(POSLUGI).map((key) => (
                <Option key={key} value={key}>
                  {POSLUGI[key]}
                </Option>
              ))}
            </Select>
          </label>
        </div>
        <div className="date-wrap">
          <Calendar value={selectedDate.startOfWeek} onSelect={onSelect} fullscreen={false} disabledDate={disabledDate} />
        </div>
      </div>

      <p className="weeek-title">{weekFormat(selectedDate)}</p>
      <div className="week-hotline-picker">
        {daysOfWeek.map((date, index) => {
          return (
            <div key={index} className="day-container">
              <h4>{daysOfWeek[index]}</h4>
              <div className="HotlineTimePicker-wrapper">
                <HotlineTimePicker
                  dayIndex={index}
                  startT={startT[index]}
                  endT={endT1[index]}
                  blockedArr={blockedArr ? (blockedArr[index] ? blockedArr[index] : []) : []}
                  onTimeClick={handleTimeClick}
                  onTimeClear={handleClickClearTime}
                  isForCRM={true}
                  date={selectedDate.startOfWeek}
                  psychologist
                  psychologistOnly={psychologistOnly}
                  type={type}
                  blockedArrInfo={blockedArrInfo ? (blockedArrInfo[index] ? blockedArrInfo[index] : []) : []}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="button-wrapper">
        <Button danger onClick={handleClickClearTime} style={{ marginRight: 20 }}>
          {translation.clear[lang]}
        </Button>
        <Button disabled={!(startT && endT)} type="primary" onClick={onClickEditHandle} style={{ backgroundColor: green.primary }}>
          Підтвердити
        </Button>
      </div>

      <ListPsychologistHotline selectedDate={selectedDate} />
    </div>
  );
};

export default PsychologistHotline;
