import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CHAT_TYPES } from 'common_constants/business';
import { CREATE_NEW_GROUP_CHAT } from 'common_constants/modals';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Col, Tabs, Spin, Button } from 'antd';
import { AutoSizer } from 'react-virtualized';
import { VariableSizeList as List } from 'react-window';
import clsx from 'clsx';

import { request, error } from '../../tools';
import { updateChat, setModal, updateUnreadedMessages } from '../../store/commonReducer';
import { tabsItems, showSearchedNoResults } from './helpers';
import ChatUser from './ChatUser';
import SearchWrapper from './SearchWrapper';

const ChatsWrapper = ({
  activeTab,
  setInputSearch,
  setContractNumberSearch,
  contractNumberSearch,
  searchedUsers,
  setActiveTab,
  setSearchedUsers,
  inputSearch,
  totalCount,
  chatUsersLoading,
  onChangeCurrentChatId,
  currentChatId,
  setCurrentPage,
  setIsTypeChatsFetching,
  setIsFetchedChat,
}) => {
  const dispatch = useDispatch();
  const listRef = useRef(null);
  const sizeMap = useRef({});

  const chatsData = useSelector((state) => state.common.chatsData);

  const [isMobile, setIsMobile] = useState(false);

  const isGroupChat = activeTab === CHAT_TYPES.groupChat.key;
  const redButtonChat = activeTab === CHAT_TYPES.redButtonChat.key;

  const setSize = useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current.resetAfterIndex(index);
  }, []);

  const getSize = (index) => sizeMap.current[index] || 0;

  useEffect(() => {
    setIsMobile(window.innerWidth <= 760);
  }, [window.innerWidth]);

  function usersScrollHandle(e) {
    if (e.scrollOffset < 150 && chatsData?.[activeTab]?.length < totalCount?.[activeTab]) {
      setIsTypeChatsFetching(true);
    }
  }

  function getChatByInterlocutorId(interlocutorId, callback) {
    request.post(
      '/chatPrivate/getchat',
      { type: activeTab, interlocutorId: interlocutorId },
      ({ data }) => {
        if (!data?._id) return;

        dispatch(updateChat({ chatType: activeTab, data: data }));
        dispatch(updateUnreadedMessages({ chatId: currentChatId, chatType: redButtonChat ? CHAT_TYPES.chatWithClient.key : activeTab }));

        if (typeof callback === 'function') callback(data);
        setIsFetchedChat(true);
      },
      error,
    );
  }
  const handleChangeActiveTab = (val) => {
    setActiveTab(val);
    setCurrentPage((prev) => ({ ...prev, [activeTab]: 1 }));
    setIsTypeChatsFetching(true);
    onChangeCurrentChatId(null);
  };

  const onClickCreateNewGroupButton = () => {
    dispatch(setModal({ name: CREATE_NEW_GROUP_CHAT }));
  };

  return (
    <Col md={{ span: 8 }} className={clsx('side-panel', isMobile && !currentChatId && '_open')}>
      <Tabs
        defaultActiveKey={activeTab}
        activeKey={activeTab}
        tabPosition="top"
        onChange={handleChangeActiveTab}
        items={tabsItems?.map((item) => ({
          key: item.key,
          label: item.label,
        }))}
        destroyInactiveTabPane={true}
        className="tabs"
        size="small"
      ></Tabs>
      <SearchWrapper
        activeTab={activeTab}
        setSearchedUsers={setSearchedUsers}
        inputSearch={inputSearch}
        setInputSearch={setInputSearch}
        contractNumberSearch={contractNumberSearch}
        setContractNumberSearch={setContractNumberSearch}
      />
      {isGroupChat && (
        <Button type="dashed" icon={<PlusOutlined />} onClick={onClickCreateNewGroupButton}>
          Створити групу
        </Button>
      )}
      <div className="users-wrapper">
        {searchedUsers?.length === 0 ? (
          <div className="empty-block">{showSearchedNoResults(activeTab, inputSearch, contractNumberSearch)}</div>
        ) : (
          <AutoSizer>
            {({ width, height }) => (
              <List
                ref={listRef}
                height={height}
                width={width}
                itemCount={searchedUsers?.length ?? 1}
                itemSize={getSize}
                onScroll={usersScrollHandle}
              >
                {({ index, style }) => {
                  const chatId = searchedUsers?.[index]?._id;

                  return (
                    <div style={style}>
                      <ChatUser
                        onChangeCurrentChatId={onChangeCurrentChatId}
                        chatId={chatId}
                        setInputSearch={setInputSearch}
                        getChatByInterlocutorId={getChatByInterlocutorId}
                        chatType={activeTab}
                        currentChatId={currentChatId}
                        isSearchedUser={inputSearch?.length > 0}
                        setSize={setSize}
                        index={index}
                      />
                    </div>
                  );
                }}
              </List>
            )}
          </AutoSizer>
        )}
      </div>
    </Col>
  );
};

export default ChatsWrapper;
