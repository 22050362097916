import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useResize } from 'common_components';

import { gold, green, volcano } from '@ant-design/colors';
import {
  BellOutlined,
  CheckOutlined,
  CloseOutlined,
  CrownOutlined,
  DeleteOutlined,
  DiffOutlined,
  FileAddOutlined,
  LoadingOutlined,
  PhoneOutlined,
  ReadOutlined,
  TabletFilled,
  UndoOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { Badge, Button, Card, Divider, Popconfirm, Spin, Tag } from 'antd';

import { HOTLINE_MEET, HOTLINE_RESULT, POSLUGI, PRAVO, RIBBON_VALUE_REACH, ROLES, TEXT_VSMS_TO_REACH_HOTLINE } from 'common_constants/business';
import { ADD_BLOCK, BELL_TO_REACH, CHAT_FOR_HOTLINE, HISTORY_HOTLINES, LIBRARY_MINI_LIST, NEW_CONTRACT } from 'common_constants/modals';
import { Box } from 'common_components';
import CheckClientWallet from '../../components/Hotline/CheckClientWallet';

import {
  setCurrentReachBellStatus,
  setCurrentReachBellStatusAnk,
  setCurrentReachBellStatusZsu,
  setCurrentReachList,
  setCurrentReachAnkList,
  setCurrentReachZsuList,
  setModal,
  updateReach,
  editReachItem,
  editReachItemAnk,
  editReachItemZsu,
} from '../../store/commonReducer';
import { error, info, request, success, warn } from '../../tools';

import HotlineContractDetails from '../Journal/HotlineContractDetails';
import HotlineMeetDetails from '../Journal/HotlineMeetDetails';
import Block5daysProject3000 from '../List/Core/Block5daysProject3000';

import clsx from 'clsx';
import './HotlineReach.scss';

import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import BlockBtn from '../../UI/BlockBtn/BlockBtn';
import HotlineRequestBody from '../Journal/HotlineRequestBody';
import SearchSelectUser from '../../components/SearchSelect/SearchSelectUser';
import SearchSelectBrigadeUF from '../../components/SearchSelect/SearchSelectBrigadeUF';
import SearchSelectBrigadeZSU from '../../components/SearchSelect/SearchSelectBrigadeZSU';

const HotlineReachItem = ({
  i,
  index,
  role,
  isInJournal,
  meetReport,
  changeContract,
  contract,
  client,
  requestBody,
  updateRecord,
  blocked,
  ankLabel,
  zsuLabel,
}) => {
  const dispatch = useDispatch();
  const resize = useResize();

  const [users, list, listAnk, listZsu, bellStatus, bellStatusAnk, bellStatusZsu, FILII] = [
    useSelector((state) => state.common.users),
    useSelector((state) => state.common.currentReachList),
    useSelector((state) => state.common.currentReachAnkList),
    useSelector((state) => state.common.currentReachZsuList),
    useSelector((state) => state.common.currentReachBellStatus),
    useSelector((state) => state.common.currentReachBellStatusAnk),
    useSelector((state) => state.common.currentReachBellStatusZsu),
    useSelector((state) => state.common.FILII),
  ];

  const usersArr = useSelector((state) => Object.values(state.common.users));
  const notFiredUsers = usersArr.filter((user) => user?.fired !== true);

  const brigadeUFUsers = notFiredUsers.filter((user) => user?.brigadeUF === true);
  const brigadeZSUUsers = notFiredUsers.filter((user) => user?.brigadeZSU === true);

  const rabfil = ROLES[role] <= ROLES.rabfil;
  const admin = ROLES[role] === ROLES.admin;

  const konsultationOrInfoCentr = i.type === '0' || i.type === '3' || i.type === '6' || i.type === '8';
  const isIC = i.type === '6';

  const relativeTime = require('dayjs/plugin/relativeTime');
  dayjs.extend(relativeTime).locale('uk');
  const now = dayjs()
    .set('hour', 0 + 2)
    .set('minute', 30)
    .set('second', 0); // * start date to 03:00:00 GMT+0300
  const nowEnd = dayjs()
    .set('hour', 0 + 20)
    .set('minute', 0)
    .set('second', 0);

  const [loading, setLoading] = useState(false);
  const [expand, setExpand] = useState(false);
  const [hellishnessInd, setSellishnessInd] = useState(i?.rrh?.rih ?? 1);
  const [hellishnessIndAnk, setSellishnessIndAnk] = useState(i?.rrh?.rihAnk ?? 1);
  const [hellishnessIndZsu, setSellishnessIndZsu] = useState(i?.rrh?.rihZsu ?? 1);
  const [date, setDate] = useState(dayjs(i?.rrh?.bel ?? false));
  const [dateAnk, setDateAnk] = useState(dayjs(i?.rrh?.belAnk ?? false));
  const [dateZsu, setDateZsu] = useState(dayjs(i?.rrh?.belZsu ?? false));
  const [derResch, setDerResch] = useState(i?.rrh?.der ?? false);
  const [historyAmount, setHistoryAmount] = useState(0);

  const { Ribbon } = Badge;

  const cancel = () => info('', 'Ви не згодні зі своїм рішенням.');

  const handleBlockDataModal = () => {
    const blockData = {
      userName: i.name,
      phoneNumber: i.phone,
    };
    dispatch(setModal({ name: ADD_BLOCK, data: blockData }));
  };
  const deletedReach = async () => {
    const validation = (data) => {
      if (!data._id) {
        warn('', 'Для зміни рівня дожиму, потрібно вказати чинного клієнта.', { 'data._id': !!data._id });
        return;
      }

      return true;
    };

    if (!validation(i)) return;

    const transaction = { _id: i._id };

    setLoading(true);

    await request.post(
      '/hotline/removeReach',
      transaction,
      (req) => {
        setDerResch(req.der);
        dispatch(
          setCurrentReachList(
            list.map((item) =>
              item._id === i._id
                ? {
                    ...item,
                    rrh: { ...i.rrh, der: req.der },
                  }
                : item,
            ),
          ),
        );
        success();
      },
      error,
    );

    setLoading(false);
  };

  const rotateReach = async () => {
    setLoading(true);

    const { _id: reachId, amm, oldAmm, fil, oldFil } = i;

    await request.post(
      '/hotline/rotateReach',
      { reachId, amm, oldAmm, fil, oldFil },
      (res) => {
        dispatch(updateReach(res.updatedReach));

        success('Дожим повернуто для', users[oldAmm]?.name);
      },
      () => {
        error('', 'Не вдалось повернути дожим');
      },
    );

    setLoading(false);
  };

  const handleTogglePreappointedUser = useCallback(
    (value) => {
      const body = value ? { _id: i._id, bmm: value, fil: notFiredUsers.find((user) => user._id === value)?.fil } : { _id: i._id, bmm: undefined };
      request.post(
        '/hotline/setOrRemovePreappointedUser',
        body,
        () => {
          dispatch(editReachItem(body));
          info('', users[value]?.name ? `${users[value].name} попередньо призначений` : `Попереднє призначення скасовано`);
        },
        () => {
          warn('', 'Щось пішло не так');
        },
      );
    },
    [i._id, i.bmm],
  );

  const handleTogglePreappointedUserAnk = useCallback(
    (value) => {
      const body = value ? { _id: i._id, ank: value } : { _id: i._id, ank: undefined };

      request.post(
        '/hotline/setOrRemovePreappointedUserAnk',
        body,
        () => {
          dispatch(editReachItemAnk(body));
          info('', users[value]?.name ? `${users[value].name} попередньо призначений` : `Попереднє призначення скасовано`);
        },
        () => {
          warn('', 'Щось пішло не так');
        },
      );
    },
    [i._id, i.ank],
  );

  const handleTogglePreappointedUserZsu = useCallback(
    (value) => {
      const body = value ? { _id: i._id, zsu: value } : { _id: i._id, zsu: undefined };

      request.post(
        '/hotline/setOrRemovePreappointedUserZsu',
        body,
        () => {
          dispatch(editReachItemZsu(body));
          info('', users[value]?.name ? `${users[value].name} попередньо призначений` : `Попереднє призначення скасовано`);
        },
        () => {
          warn('', 'Щось пішло не так');
        },
      );
    },
    [i._id, i.zsu],
  );

  const ribbonClickToHot = async () => {
    const validation = (rihSave, data) => {
      if (!rihSave && rihSave !== 0) {
        warn('', 'З індикатором сталася якась помилка.', { rihSave: rihSave });
        return;
      }

      if (!data._id) {
        warn('', 'Для зміни рівня дожиму, потрібно вказати чинного клієнта.', { 'data._id': !!data._id });
        return;
      }

      return true;
    };

    if (!validation(hellishnessInd, i)) return;

    const transaction = {
      _id: i._id,
      rih: Number(hellishnessInd) !== 3 ? Number(hellishnessInd) + 1 : 0,
    };

    setLoading(true);

    await request.post(
      '/hotline/reachTheIndicatorOfHellishness',
      transaction,
      (req) => {
        setSellishnessInd(Number(req.rih));
        dispatch(
          setCurrentReachList(
            list.map((item) =>
              item._id === i._id
                ? {
                    ...item,
                    rrh: { ...i.rrh, rih: Number(req.rih) },
                  }
                : item,
            ),
          ),
        );
        success();
      },
      error,
    );

    setLoading(false);
  };

  const ribbonClickToHotAnk = async () => {
    const validation = (rihSave, data) => {
      if (!rihSave && rihSave !== 0) {
        warn('', 'З індикатором сталася якась помилка.', { rihSave: rihSave });
        return;
      }

      if (!data._id) {
        warn('', 'Для зміни рівня дожиму, потрібно вказати чинного клієнта.', { 'data._id': !!data._id });
        return;
      }

      return true;
    };

    if (!validation(hellishnessIndAnk, i)) return;

    const transaction = {
      _id: i._id,
      rihAnk: Number(hellishnessIndAnk) !== 3 ? Number(hellishnessIndAnk) + 1 : 0,
    };

    setLoading(true);

    await request.post(
      '/hotline/reachTheIndicatorOfHellishnessAnk',
      transaction,
      (req) => {
        setSellishnessIndAnk(Number(req.rihAnk));
        dispatch(
          setCurrentReachAnkList(
            listAnk.map((item) =>
              item._id === i._id
                ? {
                    ...item,
                    rrh: { ...i.rrh, rihAnk: Number(req.rihAnk) },
                  }
                : item,
            ),
          ),
        );
        success();
      },
      error,
    );

    setLoading(false);
  };

  const ribbonClickToHotZsu = async () => {
    const validation = (rihSave, data) => {
      if (!rihSave && rihSave !== 0) {
        warn('', 'З індикатором сталася якась помилка.', { rihSave: rihSave });
        return;
      }

      if (!data._id) {
        warn('', 'Для зміни рівня дожиму, потрібно вказати чинного клієнта.', { 'data._id': !!data._id });
        return;
      }

      return true;
    };

    if (!validation(hellishnessIndZsu, i)) return;

    const transaction = {
      _id: i._id,
      rihZsu: Number(hellishnessIndZsu) !== 3 ? Number(hellishnessIndZsu) + 1 : 0,
    };

    setLoading(true);

    await request.post(
      '/hotline/reachTheIndicatorOfHellishnessZsu',
      transaction,
      (req) => {
        setSellishnessIndZsu(Number(req.rihZsu));
        dispatch(
          setCurrentReachZsuList(
            listZsu.map((item) =>
              item._id === i._id
                ? {
                    ...item,
                    rrh: { ...i.rrh, rihZsu: Number(req.rihZsu) },
                  }
                : item,
            ),
          ),
        );
        success();
      },
      error,
    );

    setLoading(false);
  };

  useEffect(() => {
    if (!bellStatus && !bellStatusAnk && !bellStatusZsu) return;

    if (bellStatus) {
      setDate(i._id === bellStatus.bellId ? bellStatus.bellDate : date);
      dispatch(setCurrentReachBellStatus(false));
    }

    if (bellStatusAnk) {
      setDateAnk(i._id === bellStatusAnk.bellId ? bellStatusAnk.bellDateAnk : dateAnk);
      dispatch(setCurrentReachBellStatusAnk(false));
    }

    if (bellStatusZsu) {
      setDateZsu(i._id === bellStatusZsu.bellId ? bellStatusZsu.bellDateZsu : dateZsu);
      dispatch(setCurrentReachBellStatusZsu(false));
    }
  }, [bellStatus, bellStatusAnk, bellStatusZsu]);

  useEffect(() => {
    request.post(
      '/hotline/getAmountByPhone',
      { phone: i.phone },
      ({ amount }) => {
        setHistoryAmount(amount);
      },
      error,
    );
  }, []);

  const style_type = derResch
    ? { backgroundColor: volcano[2] }
    : i.K
    ? { backgroundColor: green[2] }
    : now.unix() < date.unix() && date.unix() < nowEnd.unix()
    ? { backgroundColor: gold[2] }
    : {};

  const style_wants = i.qh?.qd === 2 ? { border: '3px solid #52c41a' } : {};
  const isMoved = i.oldAmm && i.oldFil && !isInJournal;

  if (!expand)
    return (
      <Spin tip="Завантаження" spinning={loading}>
        {ankLabel ? (
          <a onClick={ribbonClickToHotAnk}>
            <Ribbon
              className="ribbon-reach"
              placement="end"
              color={RIBBON_VALUE_REACH[hellishnessIndAnk].color}
              text={RIBBON_VALUE_REACH[hellishnessIndAnk].text}
            ></Ribbon>
          </a>
        ) : zsuLabel ? (
          <a onClick={ribbonClickToHotZsu}>
            <Ribbon
              className="ribbon-reach"
              placement="end"
              color={RIBBON_VALUE_REACH[hellishnessIndZsu].color}
              text={RIBBON_VALUE_REACH[hellishnessIndZsu].text}
            ></Ribbon>
          </a>
        ) : (
          <a onClick={ribbonClickToHot}>
            <Ribbon
              className="ribbon-reach"
              placement="end"
              color={RIBBON_VALUE_REACH[hellishnessInd].color}
              text={RIBBON_VALUE_REACH[hellishnessInd].text}
            ></Ribbon>
          </a>
        )}

        <a
          onClick={() =>
            dispatch(
              setModal({
                name: ankLabel ? BELL_TO_REACH : zsuLabel ? BELL_TO_REACH : BELL_TO_REACH,
                data: ankLabel ? { ...i, ankLabel } : zsuLabel ? { ...i, zsuLabel } : i,
              }),
            )
          }
        >
          <Ribbon
            className="ribbon-reach-2"
            placement="end"
            color={date.unix() > now.unix() ? 'volcano' : 'gold'}
            text={
              <div>
                <span>
                  {ankLabel
                    ? dateAnk.unix() > now.unix()
                      ? dateAnk.format('DD/MM')
                      : ''
                    : zsuLabel
                    ? dateZsu.unix() > now.unix()
                      ? dateZsu.format('DD/MM')
                      : ''
                    : date.unix() > now.unix()
                    ? date.format('DD/MM')
                    : ''}
                </span>{' '}
                <BellOutlined />
              </div>
            }
          />
        </a>

        {isMoved && (
          <Ribbon
            className="ribbon-reach-3"
            placement="start"
            style={{ backgroundColor: '#6a4bc3', zIndex: 1 }}
            text={
              <div>
                <span>Чужий</span>
              </div>
            }
          ></Ribbon>
        )}

        <Card
          style={{ ...style_type, ...style_wants }}
          key={index}
          className={clsx({ 'card hotline-reach-item two_columns': true, disabled: i.disabled, edited: i.e, error: blocked })}
          onClick={() => setExpand(true)}
        >
          <div className="row">
            <span className="item-white-space">
              <b>Філія:</b> {FILII[i.fil] ?? '-'}
            </span>
          </div>
          <div className="row">
            <b>Запр{resize[0] >= 500 ? 'опонована' : '.'} сума:</b> {i.amp ?? '-'}
          </div>
          {ankLabel ? null : zsuLabel ? null : (
            <div className="row">
              <b>{resize[0] >= 500 ? 'Призначено:' : 'При-но:'}</b> {(users && users[i.amm]?.p) ?? '-'}
            </div>
          )}

          {ankLabel && (
            <div className="row">
              <b>{resize[0] >= 500 ? 'Призначено:' : 'При-но:'}</b> {(users && users[i.ank]?.p) ?? '-'}
            </div>
          )}
          {zsuLabel && (
            <div className="row">
              <b>{resize[0] >= 500 ? 'Призначено:' : 'При-но:'}</b> {(users && users[i.zsu]?.p) ?? '-'}
            </div>
          )}
          <div className="row">
            <b>Дата:</b> {dayjs(i.date)?.format('DD.MM.YYYY') ?? '-'}
          </div>
        </Card>
      </Spin>
    );

  return (
    <>
      <Card
        style={{ ...style_type, ...style_wants }}
        key={index}
        className={clsx({ 'card hotline-reach-item two_columns': true, disabled: i.disabled, edited: i.e, error: blocked })}
      >
        <Spin size="large" tip="Завантаження" spinning={loading}>
          {blocked && (
            <div className="block-wrapper">
              <Tag color="error">Користувач заблокований</Tag>
            </div>
          )}
          {i.vip && (
            <div className="row full vip_block">
              <span>
                VIP <CrownOutlined style={{ color: 'gold', width: '16px', marginLeft: 8 }} />
              </span>
            </div>
          )}
          {i.vip && <div className="row full vip_block"></div>}
          <div className="row">
            <span className="item-white-space">
              <b>Філія:</b> {FILII[i.fil] ?? '-'}
            </span>
          </div>
          <div className="row">
            <span className="item-white-space">
              <b>Послуга:</b> {POSLUGI[i.type] ?? '-'}
            </span>
          </div>
          <div className="row">
            <b>Дата:</b> {dayjs(i.date)?.format('DD.MM.YYYY') ?? '-'}
          </div>
          <div className="row">
            <span className="item-white-space">
              <b>Ім'я:</b> {i.name ?? '-'}
            </span>
          </div>
          <div className="row">
            <b>Час:</b> {i.startT ?? '-'} - {i.endT ?? '-'}
          </div>
          <div className="row">
            <b>{resize[0] >= 500 ? 'Телефон:' : 'Тел.:'}</b> {i.phone ?? '-'}
          </div>
          <div className="row">
            <span className="item-white-space">
              <b>Вид:</b> {PRAVO[i.pravo] ?? '-'}
            </span>
          </div>
          <div className="row">
            <b>Сума:</b> {i.cost ?? '-'}
          </div>
          <div className="row">
            <span className="item-white-space">
              <b>Коментар:</b> {i.comment ?? '-'}
            </span>
          </div>
          {konsultationOrInfoCentr ? (
            <div className="row">
              <b>{resize[0] >= 500 ? 'Прийнято, як халтуру:' : 'Халтура'}</b> {i.isAcceptedHackwork ? 'так' : '-'}
            </div>
          ) : (
            <div className="row"></div>
          )}

          <Divider className="divider-hotline-item-info" />
          <div className="row"></div>

          <div className="row">
            <b>Автор:</b> {(window.users && window.users[i.author]?.p) ?? '-'}
          </div>
          <div className="row">
            <b>Створено:</b> {dayjs(i.crAt)?.format('DD.MM.YYYY') ?? '-'}
          </div>
          {konsultationOrInfoCentr ? (
            <div className="row">
              <b>{resize[0] >= 500 ? 'Призначено:' : 'При-но:'}</b> {(users && users[i.amm]?.p) ?? '-'}
            </div>
          ) : (
            <div className="row"></div>
          )}
          {konsultationOrInfoCentr ? (
            <div className="row">
              <b>Запр{resize[0] >= 500 ? 'опонована' : '.'} сума:</b> {i.amp ?? '-'}
            </div>
          ) : (
            <div className="row"></div>
          )}
          <div className="row">
            <span className="item-white-space">
              <b>{resize[0] >= 500 ? 'Результат' : 'Рез.'} зустрічі: </b> {konsultationOrInfoCentr ? HOTLINE_RESULT[i.amr] ?? '-' : HOTLINE_MEET[i.s]}
            </span>
          </div>
          <div className="row">
            <b>№ консультації: </b>
            {i.i}
          </div>
          {i.s ? (
            <div className="row">
              <b>Анкетування:</b>
              {i.s === '1' ? <CheckOutlined /> : i.s === '2' ? <CloseOutlined /> : i.s === '3' ? <PhoneOutlined /> : <DiffOutlined />}
            </div>
          ) : (
            <div className="row"></div>
          )}
          <Divider className="divider-hotline-item-info" />
          <div className="row"></div>
          <div className="row">
            <span className="item-white-space">
              <b>Тип дожиму:</b> {TEXT_VSMS_TO_REACH_HOTLINE[i?.rrh?.rsm]?.label ?? '-'}
            </span>
          </div>
          <CheckClientWallet userBalance={i.hotlineMatch?.clientBalance} userBonus={i.hotlineMatch?.B} />
          <div className="row">
            <span className="item-white-space">
              <b>Опис звіту:</b> {i?.rrh?.rdr ?? '-'}
            </span>
          </div>
          <div className="row"></div>
          <div className="row full controls">
            {!isInJournal && (
              <div>
                {(ankLabel || zsuLabel) && <>Призначено: </>}
                <SearchSelectUser i={i} usersArr={notFiredUsers} isIC={isIC} handleTogglePreappointedUser={handleTogglePreappointedUser} />
              </div>
            )}
            {!isInJournal && ankLabel && (
              <div>
                Бригада УФ:{' '}
                <SearchSelectBrigadeUF i={i} usersArr={brigadeUFUsers} handleTogglePreappointedUserAnk={handleTogglePreappointedUserAnk} />
              </div>
            )}
            {!isInJournal && zsuLabel && (
              <div>
                Бригада ЗСУХ:{' '}
                <SearchSelectBrigadeZSU i={i} usersArr={brigadeZSUUsers} handleTogglePreappointedUserZsu={handleTogglePreappointedUserZsu} />
              </div>
            )}
            <br />
            {derResch && <div style={{ color: 'red' }}>Видалено</div>}
            {i.K !== undefined && <div style={{ color: 'green' }}>Дожали</div>}
            {i.topBell === 1 && <div style={{ color: volcano[5] }}>Є нагадування</div>}
            {i.qh?.qd === 2 && <div style={{ color: 'green' }}>Хоче укласти з нами договір</div>}
            <Box mt={12} className="group-button">
              {konsultationOrInfoCentr && rabfil && !isInJournal && (
                <Button
                  className="contact btn-reach"
                  type="primary"
                  disabled={i.s !== '1' || i.K}
                  onClick={() => dispatch(setModal({ name: NEW_CONTRACT, data: { hotline: i }, hotline: i }))}
                >
                  {<FileAddOutlined />} Додати договір
                </Button>
              )}
              {konsultationOrInfoCentr && rabfil && !isInJournal && (
                <Button
                  className=" btn-reach"
                  type="primary"
                  onClick={() =>
                    dispatch(
                      setModal({
                        name: CHAT_FOR_HOTLINE,
                        data: {
                          hotlineId: i._id,
                          hotlineTitle: 'Чат по запису за тел. ' + (i.phone ?? '-') + ' на ' + (dayjs(i.date)?.format('DD.MM.YYYY') ?? '-'),
                        },
                      }),
                    )
                  }
                  icon={<TabletFilled />}
                >
                  Чат
                </Button>
              )}
            </Box>
            <Box mt={12} className="group-button">
              <Button onClick={() => dispatch(setModal({ name: HISTORY_HOTLINES, data: { phone: i.phone } }))} className="btn-reach">
                Історія ({historyAmount})
              </Button>
              <Button
                onClick={() => dispatch(setModal({ name: LIBRARY_MINI_LIST, data: { shoo: i.pravo } }))}
                className="btn-reach"
                type="primary"
                icon={<ReadOutlined />}
              >
                Міні бібліотека
              </Button>
            </Box>
            <br />

            {konsultationOrInfoCentr && rabfil && !isInJournal && <Block5daysProject3000 data={i} setLoading={(item) => setLoading(item)} />}

            <Box mt={12} className="group-button">
              {konsultationOrInfoCentr && rabfil && !isInJournal && (
                <Popconfirm
                  title="Підтвердження"
                  description="Ви впевнені, що хочете видалити цю консультацію з дожиму?"
                  onConfirm={() => deletedReach()}
                  onCancel={cancel}
                  okText="Гаразд"
                  cancelText="Скасувати"
                  className="btn-reach"
                >
                  <Button type="primary" disabled={i.s !== '1' || i.K} className="btn-reach-item-popc" danger icon={<DeleteOutlined />}>
                    Видалити з дожиму
                  </Button>
                </Popconfirm>
              )}
              <BlockBtn myStyle="btn-reach" data={i} phone={i.phone} blocked={blocked} modal={handleBlockDataModal} updateRecord={updateRecord} />
            </Box>

            <Box mt={12}>
              {admin && isMoved && (
                <Button
                  disabled={loading}
                  icon={loading ? <LoadingOutlined /> : <UndoOutlined />}
                  className="contact"
                  type="primary"
                  onClick={rotateReach}
                >
                  {`Повернути для : ${users[i.oldAmm]?.name}`}
                </Button>
              )}
            </Box>
          </div>
          <div className="btns">
            <Button className="expand" onClick={() => setExpand(false)}>
              <UpOutlined />
            </Button>
          </div>
        </Spin>
      </Card>
      {isInJournal && meetReport && <HotlineMeetDetails hotline={i} />}
      {isInJournal && changeContract && <HotlineContractDetails contract={contract} client={client} />}
      {isInJournal && <HotlineRequestBody requestBody={requestBody} />}
    </>
  );
};

export default HotlineReachItem;
