import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { Card, Col, Row, Checkbox, Popconfirm, message, Button, Spin } from 'antd';
import { TYPES_OF_MAIL, EXPENSE_CATEGORIES } from 'common_constants/business';
import { selectUsers, setModal, setCurrentClient } from '../../../../store/commonReducer';
import { request, error } from '../../../../tools';
import { ROLES } from 'common_constants/business';
import { WALLET_ASSIGNMENT, CONFIRM_ADD_CONTRACT, INFO_EXPENSE } from 'common_constants/modals';
import { getContractByIndex } from '../../Helpers';
import EditSugar from '../../../../Modals/AddSugar/EditSugar';

const OTHER_CATEGORIES = {
  correspondence: {
    label: 'Кореспонденція',
  },
  payroll: {
    label: 'Відсотки за договором',
  },
  peredast: {
    label: 'Відсотки за передаст',
  },
};

const ExpensesItem = ({ expense, handleSetApproved }) => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.common.userAuth);
  const users = useSelector(selectUsers);
  const correspondenceType = TYPES_OF_MAIL.find((type) => type.value == expense.p)?.label;
  const payrollUser = users[expense?.user];
  const inspector = ROLES[userAuth.role] <= ROLES.inspector;
  const [loadApproved, setLoadApproved] = useState(false);

  const [editSugarModalVisible, setEditSugarModalVisible] = useState(false);
  const [selectedEditSugarObject, setSelectedEditSugarObject] = useState(null);

  const [loading, setLoading] = useState(false);

  const onChangeApproved = (e) => {
    e.stopPropagation();
    if (!inspector) {
      message.warning('У вас немає прав на зміну статусу перевірки.');
      return;
    }
    setLoadApproved(true);
    let type = 'expense';
    if (expense.category === 1) {
      type = 'assignment';
    }
    if (expense.otherCategory === 'correspondence') {
      type = 'correspondence';
    }
    const route = `/${type}/setApproved`;
    const body = { documentId: expense._id, status: !expense?.approved?.status };
    const onSuccess = (res) => {
      handleSetApproved(expense._id, res.approved);
      setLoadApproved(false);
      message.success('Статус перевірки змінено.');
    };
    const onError = (err) => {
      setLoadApproved(false);
      message.error('Не вдалось змінити статус перевірки.');
    };
    request.post(route, body, onSuccess, onError);
  };

  const handleOpenModalInfoClick = (e) => {
    e.stopPropagation();
    switch (expense?.category) {
      case 1:
        dispatch(
          setModal({
            name: WALLET_ASSIGNMENT,
            data: { contractIndex: expense?.contractIndex, assignmentId: expense._id },
          }),
        );
        break;
      default:
        handleGetContractByIndex();
        break;
    }
  };

  const handleGetContractByIndex = async () => {
    setLoading(true);

    await getContractByIndex({
      index: expense?.contractIndex,
      contractId: expense?.contractId,
      sugarId: expense?.sugarId,
      sugarIndex: expense?.sugarIndex,
      dispatch,
      setCurrentClient,
      setModal,
      CONFIRM_ADD_CONTRACT,
    });

    setLoading(false);
  };

  const showExpenseModal = () => {
    dispatch(
      setModal({
        name: INFO_EXPENSE,
        data: { expense },
      }),
    );
  };

  const showEditSugarModal = async () => {
    setLoading(true);
    await request.post(
      '/sugar/getSugarByIdOrIndex',
      { sugarId: expense?.sugarId, i: expense?.sugarIndex },
      ({ sugar }) => {
        setSelectedEditSugarObject(sugar);
      },
      error,
    );

    setLoading(false);
    setEditSugarModalVisible(true);
  };

  const handleClick = () => {
    if (expense?.otherCategory !== undefined) showEditSugarModal();
    else showExpenseModal();
  };

  const hideEditSugarModal = (e) => {
    e.stopPropagation();
    setEditSugarModalVisible(false);
    setSelectedEditSugarObject(null);
  };

  let itemId = '';
  if (!expense.category && expense.otherCategory === 'correspondence') {
    itemId += 'correspondence_' + expense._id;
  }
  if (!expense.category && expense.otherCategory === 'payroll') {
    itemId += 'payroll_' + expense._id;
  }
  if (expense.category) {
    itemId += 'expense_' + expense._id;
  }

  return (
    <Spin spinning={loading}>
      <Card className="expensesInner" style={{ cursor: 'pointer' }} id={itemId} onClick={handleClick}>
        <Row className="item" justify={'space-between'} align={'middle'}>
          <Col className="fields-inner" flex={'auto'}>
            <Row className="fields" justify={'space-between'}>
              <Row className="row">
                <Row className="info">{dayjs(expense.date).format('DD.MM.YYYY')}</Row>
              </Row>
              <Row className="row">
                <Row className="info">{expense?.amount}</Row>
              </Row>
              <Row className="row">
                <Row className="info">
                  {expense?.contractId || expense?.contractIndex || expense?.sugarId || expense?.sugarIndex ? (
                    <Button type="primary" style={{ marginTop: 15 }} onClick={handleOpenModalInfoClick}>
                      {EXPENSE_CATEGORIES[expense?.category]?.label}
                      {expense?.category ? null : OTHER_CATEGORIES[expense.otherCategory]?.label}
                    </Button>
                  ) : (
                    <>
                      {EXPENSE_CATEGORIES[expense?.category]?.label}
                      {expense?.category ? null : OTHER_CATEGORIES[expense.otherCategory]?.label}
                    </>
                  )}
                </Row>
              </Row>
              <Row className="row">
                <Row className="info">
                  {EXPENSE_CATEGORIES[expense?.category]?.subcategories[expense?.subCategory]?.label}
                  {expense?.otherCategory === 'payroll' ? payrollUser?.p : null}
                  {expense?.otherCategory === 'correspondence' ? correspondenceType : null}
                </Row>
              </Row>
              <Row className="row">
                <Row className="info">
                  <Popconfirm
                    title={`Витрату ${expense.approved?.status ? '' : 'не'} перевірено`}
                    description="Бажаєте змінити статус перевірки?"
                    onConfirm={onChangeApproved}
                    okText="Так"
                    cancelText="Ні"
                    onClick={(e) => e.stopPropagation()}
                    onCancel={(e) => e.stopPropagation()}
                  >
                    <Checkbox checked={expense?.approved?.status} disabled={loadApproved}>
                      <Row style={{ fontSize: '12px' }}>{users[expense.approved?.userId]?.p ?? '-'}</Row>
                    </Checkbox>
                  </Popconfirm>
                </Row>
              </Row>
            </Row>
          </Col>
        </Row>
      </Card>

      <EditSugar disabledForBalance={true} open={editSugarModalVisible} onCancel={hideEditSugarModal} data={selectedEditSugarObject} />
    </Spin>
  );
};

export default React.memo(ExpensesItem);
