import React, { useEffect, useRef, useState } from 'react';
import { Input, Button, Spin } from 'antd';
import { SendOutlined, PaperClipOutlined, CloseOutlined } from '@ant-design/icons';
import { tools } from 'common_components';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { error } from '../../tools';
import { addNewMessageToChat, updateChat } from '../../store/commonReducer';
import MessagesList from './MessagesList';

import './style.scss';

const Chat = ({
  onSendMessage,
  onEditMessage,
  onSendFile,
  onGetMessages,
  onLoadMoreMessages,
  onGetFile,
  isDisabled,
  chatType,
  chatId,
  fetchedMessageIndex,
  pinnedMessageScrollId,
}) => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.common.userAuth);
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);

  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState([]);
  const [sendLoader, setSendLoader] = useState(false);
  const [messagesLoader, setMessagesLoader] = useState(false);
  const [messagesCount, setMessagesCount] = useState(0);
  const [isMoreMessagesFetching, setIsMoreMessagesFetching] = useState(false);
  const [prevMessagesCount, setPrevMessagesCount] = useState(0);
  const [editMode, setEditMode] = useState({
    isEditing: false,
    messageId: '',
    prevMessageValue: '',
  });

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  const makeMyMessage = (text) => ({
    message: text,
    sender: userAuth._id,
    date: new Date(),
    name: userAuth.name,
    isSentByCurrentUser: true,
  });

  const makeMyFileMessage = ({ fileId, fileName, fileSize }) => ({
    fileId,
    fileName,
    fileSize,
    sender: userAuth._id,
    date: new Date(),
    name: userAuth.name,
    isSentByCurrentUser: true,
  });

  const handleSend = () => {
    if (inputValue === '') return;
    setSendLoader(true);
    setInputValue('');

    if (editMode.isEditing) {
      onEditMessage({
        messageId: editMode.messageId,
        message: inputValue,
        callback: (res) => {
          resetEditModeData();
          setSendLoader(false);
        },
      });

      return;
    }

    onSendMessage({
      message: inputValue,
      callback: (res) => {
        if (chatType && chatId) {
          dispatch(addNewMessageToChat({ chatType, data: res.newMessage, chatId }));
        } else {
          setMessages([...messages, makeMyMessage(inputValue)]);
        }
        setSendLoader(false);
      },
    });
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSend();
    }
  };

  const handleAttachFile = (event) => {
    const files = event.target.files;

    if (!files || files.length === 0) return;
    setSendLoader(true);

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append('files', file, tools.translit(file.name));
    }

    // const file = event.target.files[0];

    // if (!file) return;
    // setSendLoader(true);

    // const formData = new FormData();
    // formData.append('file', file, tools.translit(file.name));

    onSendFile({
      formData: formData,
      callback: (res) => {
        fileInputRef.current.value = '';
        if (Array.isArray(res.data)) {
          const newMessages = res.data.map((fileData) => makeMyFileMessage(fileData));
          if (chatType && chatId) {
            for (const fileData of newMessages) {
              dispatch(addNewMessageToChat({ chatType: chatType, chatId: chatId, data: makeMyFileMessage(fileData) }));
            }
          }
          setMessages([...messages, ...newMessages]);
        } else {
          if (chatType && chatId) {
            dispatch(addNewMessageToChat({ chatType: chatType, chatId: chatId, data: makeMyFileMessage(res.data) }));
          }
          setMessages([...messages, makeMyFileMessage(res.data)]);
        }

        // if (chatType && chatId) {
        //   dispatch(addNewMessageToChat({ chatType: chatType, chatId: chatId, data: makeMyFileMessage(res.data) }));
        // }

        setSendLoader(false);
      },
      onError: () => {
        setSendLoader(false);
        fileInputRef.current.value = '';
        error('Помилка при відправці файлу');
      },
    });
  };

  const resetEditModeData = () => {
    setEditMode({
      isEditing: false,
      messageId: null,
      prevMessageValue: '',
    });
  };

  const handleEditMode = (item) => {
    setInputValue(item.message);
    setEditMode({
      isEditing: true,
      messageId: item._id,
      prevMessageValue: item.message,
    });
  };

  const handleLoadMoreMessages = () => {
    setPrevMessagesCount(messages?.length);
    onLoadMoreMessages(messages?.length, (data) => {
      dispatch(updateChat({ chatType: chatType, data: { _id: chatId, messages: [...(data.messages ?? []), ...(messages ?? [])] } }));
      setMessages((prev) => [...(data.messages ?? []), ...(prev ?? [])]);
      setMessagesCount((prev) => data.messagesCount ?? prev);
      setIsMoreMessagesFetching(false);
    });
  };

  const onCloseEditingModeButton = () => {
    resetEditModeData();
    setInputValue('');
  };

  useEffect(() => {
    setMessagesLoader(true);
    onGetMessages(({ messages, messagesCount }) => {
      setMessages(messages);
      setMessagesCount(messagesCount);
      setMessagesLoader(false);
    });
  }, [onGetMessages]);

  useEffect(() => {
    resetEditModeData();
    setInputValue('');
  }, [chatId, chatType]);

  useEffect(() => {
    if (!isMoreMessagesFetching) return;

    handleLoadMoreMessages();
  }, [isMoreMessagesFetching]);

  return (
    <div className="chat-container">
      <div className="chat-comp-wrapper">
        <MessagesList
          chatId={chatId}
          chatType={chatType}
          messages={messages}
          messagesCount={messagesCount}
          setMessagesLoader={setMessagesLoader}
          onGetFile={onGetFile}
          handleEditMode={handleEditMode}
          setIsMoreMessagesFetching={setIsMoreMessagesFetching}
          isMoreMessagesFetching={isMoreMessagesFetching}
          prevMessagesCount={prevMessagesCount}
          fetchedMessageIndex={fetchedMessageIndex}
          pinnedMessageScrollId={pinnedMessageScrollId}
        />
        <div className="input-container" ref={inputRef}>
          <div className={clsx('edit-mode', !editMode.isEditing && '_hidden')}>
            <div className="block">
              <p className="editing-text">Редагування</p>
              <p className="prev-message">{editMode.prevMessageValue}</p>
            </div>
            <Button className="close-btn" onClick={onCloseEditingModeButton} icon={<CloseOutlined />} type="text" />
          </div>

          <div className="new-message-wrapper">
            <input type="file" onChange={handleAttachFile} style={{ display: 'none' }} ref={fileInputRef} multiple aria-label="download files" />
            <Button
              type="text"
              onClick={() => fileInputRef.current.click()}
              loading={sendLoader}
              icon={<PaperClipOutlined />}
              disabled={isDisabled || editMode.isEditing}
              className="file-btn"
            />
            <Input placeholder="Напишіть повідомлення..." value={inputValue} onChange={handleInput} onKeyDown={handleKeyDown} disabled={isDisabled} />
            <Button type="primary straight-left" onClick={handleSend} loading={sendLoader} icon={<SendOutlined />} disabled={isDisabled} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
