import { CHAT_TYPES } from 'common_constants/business';

export const tabsItems = Object.values(CHAT_TYPES)?.map((item) => ({
  ...item,
  label: (
    <div className="tab-item">
      <p className="tab-text">{item.label}</p>
    </div>
  ),
}));

export const getSearchInputPlaceholder = (activeTab) => {
  return activeTab === CHAT_TYPES.groupChat.key
    ? 'Введіть назву гурпи'
    : activeTab === CHAT_TYPES.lawyerChat.key
    ? "Введіть ім'я або філію адвоката"
    : activeTab === CHAT_TYPES.chatWithClient.key
    ? 'Введіть номер договору'
    : activeTab === CHAT_TYPES.contractChat.key
    ? 'Введіть номер договору'
    : activeTab === CHAT_TYPES.redButtonChat.key
    ? 'Введіть номер договору'
    : '';
};

export const showSearchedNoResults = (activeTab, inputSearch, contractNumberSearch) => {
  return inputSearch === '' ? (
    <p>Немає наявних чатів</p>
  ) : activeTab === CHAT_TYPES.lawyerChat.key ? (
    <p>
      Користувача по імені або по філії: <b>{inputSearch}</b> не знайдено.
    </p>
  ) : activeTab === CHAT_TYPES.groupChat.key ? (
    <p>
      Груп з назвою <b>{inputSearch}</b> не знайдено
    </p>
  ) : activeTab === CHAT_TYPES.chatWithClient.key ? (
    <p>
      Клієнта з договору <b>№:{contractNumberSearch}</b> не знайдено.
    </p>
  ) : activeTab === CHAT_TYPES.contractChat.key || activeTab === CHAT_TYPES.redButtonChat.key ? (
    <p>
      Контракт <b>№:{contractNumberSearch}</b> не знайдено.
    </p>
  ) : (
    ''
  );
};

export const getChatName = (chat, allUsers) => {
  if (chat?.isGroup && chat?.chatName) {
    return chat.chatName;
  }

  switch (chat?.type) {
    case CHAT_TYPES.contractChat?.key:
    case CHAT_TYPES.redButtonChat?.key:
    case CHAT_TYPES.chatWithClient?.key:
      return `${chat?.clientInfo?.[0]?.n || 'Невідомо'} №${chat?.i}`;
    default:
      return allUsers?.find((user) => chat?.users?.includes(user._id))?.p || '';
  }
};

export const getChatShortName = (chat, allUsers) => {
  if (chat?.isGroup && chat?.chatName) {
    return chat.chatName;
  }

  switch (chat?.type) {
    case CHAT_TYPES.contractChat?.key:
    case CHAT_TYPES.redButtonChat?.key:
    case CHAT_TYPES.chatWithClient?.key:
      return `№${chat?.i}`;
    default:
      return allUsers?.find((user) => chat?.users?.includes(user._id))?.p || '';
  }
};
