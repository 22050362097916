import { createSlice } from '@reduxjs/toolkit';
import { ACCESS_TOKEN, CHAT_TYPES, WEBINAR_STATUSES } from 'common_constants/business';
import { getTokenData } from '../tools';

const userToken = window.localStorage.getItem(ACCESS_TOKEN);
const userAuth = getTokenData(userToken);

const initialState = {
  modal: { name: '', data: {} },
  contracts: [],
  contractsWithoutBlockCount: 0,
  currentClientSugar: null,
  clients: [],
  currentClient: null,
  currentHotlineList: null,
  currentPsychologistHotline: null,
  currentPsychologistHotlineCalendar: null,
  currentHackworksList: null,
  harvest: null,
  currentReachList: null,
  currentReachAnkList: null,
  currentReachZsuList: null,
  currentReachBellStatus: false,
  currentReachBellStatusAnk: false,
  currentReachBellStatusZsu: false,
  currentUserInfo: false,
  reqLoader: false,
  userAuth: userAuth && { ...userAuth, token: userToken },
  appPrepared: false,
  users: null,
  lawyersList: null,
  assignmentsList: null,
  judgesList: null,
  placesList: null,
  courtsList: null,
  filii: null,
  library: null,
  libraryMiniForModal: null,
  bankCards: null,
  correspondenceModalForTheContract: null,
  correspondencePage: null,
  currentCorrespondence: null,
  contractsRating: null,
  reachesRating: null,
  pravoRating: null,
  clubCardsList: null,
  autoRotateReachesState: null,
  feeList: null,
  clientFeedbackList: null,
  currentAuthUserPersonalDate: {},
  userPersonalData: {},
  actualization: {},
  topFilials: null,
  unreadedMessagesTotal: [],
  unreadedMessagesData: [],
  user: null,
  expenses: null,
  arrAddressees: [],
  tasks: {},
  tasksFilter: {
    fil: null,
    appointed: [],
    contractIndex: null,
    author: null,
  },
  sugar: [],
  pushNotification: [],
  unwatchedTasksCount: null,
  mfo: null,
  conflicts: [],
  allTweets: [],
  userTweets: [],
  userFavoriteTweets: [],
  quickChats: null,
  chatsData: {},
  socket: null,
  onlineUsers: [],
  webinars: [],
  templates: [],
  allInsuranceTypes: [],
  insuranceTypes: [],
  insurances: [],
  filialWorkers: [],
  dublicates: {
    sugars: {
      data: {},
      total: 1,
    },
    payrolls: {
      data: {},
      total: 1,
    },
    assignments: {
      data: {},
      total: 1,
    },
  },
  contractsChatsFilter: {
    [CHAT_TYPES.chatWithClient.key]: {
      fil: '',
    },
    [CHAT_TYPES.contractChat.key]: {
      fil: '',
    },
  },
  hotlineCalls: [],
  hotlineCallsFilter: {
    fil: '',
  },
};

const [groupCorPage, groupCorContracts, groupCorGeneral] = [
    {
      setCorrespondenceListModalForTheContract: (state, action) => {
        state.correspondenceModalForTheContract = action.payload;
      },
      addCorrespondenceItemModalForTheContract: (state, action) => {
        state.correspondenceModalForTheContract = [action.payload, ...state.correspondenceModalForTheContract];
        state.correspondenceModalForTheContract?.sort((a, b) => new Date(b.t) - new Date(a.t));
      },
      editCorrespondenceItemModalForTheContract: (state, action) => {
        state.correspondenceModalForTheContract = state.correspondenceModalForTheContract?.map((item) =>
          item._id === action.payload._id ? { ...item, ...action.payload } : item,
        );
        state.correspondenceModalForTheContract?.sort((a, b) => new Date(b.t) - new Date(a.t));
      },
      deleteCorrespondenceContractModalForItem: (state, action) => {
        state.correspondenceModalForTheContract = state.correspondenceModalForTheContract?.filter((item) => item._id !== action.payload);
      },
    },
    {
      setCorrespondenceListPage: (state, action) => {
        state.correspondencePage = action.payload;
      },
      addCorrespondenceItemPage: (state, action) => {
        state.correspondencePage = [action.payload, ...state.correspondencePage];
        state.correspondencePage?.sort((a, b) => new Date(b.t) - new Date(a.t));
      },
      editCorrespondenceItemPage: (state, action) => {
        state.correspondencePage = state.correspondencePage?.map((item) => (item._id === action.payload._id ? { ...item, ...action.payload } : item));
        state.correspondencePage?.sort((a, b) => new Date(b.t) - new Date(a.t));
      },
      deleteCorrespondencePageForItem: (state, action) => {
        state.correspondencePage = state.correspondencePage?.filter((item) => item._id !== action.payload);
      },
    },
    {
      setCurrentCorrespondence: (state, action) => {
        state.currentCorrespondence = action.payload;
      },
    },
  ],
  groupCorrespondenceAll = { ...groupCorPage, ...groupCorContracts, ...groupCorGeneral };

const groupLibraryAll = {
  setLibraryList: (state, action) => {
    state.library = action.payload;
  },
  deleteLibraryItem: (state, action) => {
    state.library = state.library.filter((item) => item._id !== action.payload);
  },
  addLibraryItem: (state, action) => {
    state.library = [action.payload, ...state.library];
  },
  setLibraryMiniForModal: (state, action) => {
    state.libraryMiniForModal = action.payload;
  },
};

const groupBankCardsAll = {
  setBankCardsList: (state, action) => {
    state.bankCards = action.payload;
    state.bankCards?.sort((a, b) => (a.d && !b.d ? 1 : !a.d && b.d ? -1 : b.i - a.i));
  },
  editBankCardsItem: (state, action) => {
    state.bankCards = state.bankCards?.map((item) => (item._id === action.payload._id ? { ...item, ...action.payload } : item));
    state.bankCards?.sort((a, b) => (a.d && !b.d ? 1 : !a.d && b.d ? -1 : b.i - a.i));
  },
  addBankCardsItem: (state, action) => {
    state.bankCards = [action.payload, ...state.bankCards];
    state.bankCards?.sort((a, b) => (a.d && !b.d ? 1 : !a.d && b.d ? -1 : b.i - a.i));
  },
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    ...groupBankCardsAll,
    ...groupLibraryAll,
    ...groupCorrespondenceAll,
    setUserAuth: (state, action) => {
      state.userAuth = action.payload;
      state.appPrepared = false;
    },
    setAppPrepared: (state, action) => {
      state.appPrepared = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
      state.userAuth = {
        ...window.users?.[state.userAuth.username],
        ...state.userAuth,
      };
    },
    setCurrentClient: (state, action) => {
      state.currentClient = action.payload;
    },
    setCurrentPsychologistHotline: (state, action) => {
      state.currentPsychologistHotline = action.payload;
    },
    setCurrentPsychologistHotlineCalendar: (state, action) => {
      state.currentPsychologistHotlineCalendar = action.payload;
    },
    setCurrentHotlineList: (state, action) => {
      state.currentHotlineList = action.payload;
    },
    setCurrentSearchList: (state, action) => {
      state.currentSearchList = action.payload;
    },
    editUser: (state, action) => {
      state.users = { ...state.users, ...action.payload };
    },
    setCurrentHackworksList: (state, action) => {
      state.currentHackworksList = action.payload;
    },
    setHarvestList: (state, action) => {
      state.harvest = action.payload;
    },
    editHarvest: (state, action) => {
      state.harvest = state.harvest.map((item) => (item._id === action.payload._id ? { ...item, ...action.payload } : item));
    },
    setClientsList: (state, action) => {
      state.clients = action.payload;
    },
    editClient: (state, action) => {
      const clientIndex = state.clients.findIndex((i) => i._id === action.payload._id);
      state.clients[clientIndex] = { ...state.clients[clientIndex], ...action.payload };
    },
    editHotline: (state, action) => {
      // payload = значення яке передається
      const hotlineIndex = state.currentHotlineList.findIndex((i) => i._id === action.payload._id);
      state.currentHotlineList[hotlineIndex] = { ...state.currentHotlineList[hotlineIndex], ...action.payload };
    },
    editReachItem: (state, action) => {
      const listKey = state.currentReachAnkList ? 'currentReachAnkList' : state.currentReachList ? 'currentReachList' : 'currentReachZsuList';

      const hotlineIndex = state[listKey].findIndex((i) => i._id === action.payload._id);

      state[listKey][hotlineIndex] = {
        ...state[listKey][hotlineIndex],
        ...action.payload,
      };
    },

    editReachItemAnk: (state, action) => {
      const hotlineIndex = state.currentReachAnkList.findIndex((i) => i._id === action.payload._id);
      state.currentReachAnkList[hotlineIndex] = { ...state.currentReachAnkList[hotlineIndex], ...action.payload };
    },
    editReachItemZsu: (state, action) => {
      const hotlineIndex = state.currentReachZsuList.findIndex((i) => i._id === action.payload._id);
      state.currentReachZsuList[hotlineIndex] = { ...state.currentReachZsuList[hotlineIndex], ...action.payload };
    },
    setPartnersList: (state, action) => {
      state.partners = action.payload;
    },
    setPartnerBalance: (state, action) => {
      const { partnerId, amount } = action.payload;
      const partnerIndex = state.partners.findIndex((partner) => partner._id === partnerId);
      if (partnerIndex !== -1) {
        state.partners[partnerIndex].partner_balance = amount;
      }
    },
    setCurrentReachList: (state, action) => {
      state.currentReachList = action.payload;
    },
    setCurrentReachAnkList: (state, action) => {
      state.currentReachAnkList = action.payload;
    },
    setCurrentReachZsuList: (state, action) => {
      state.currentReachZsuList = action.payload;
    },
    setCurrentReachBellStatus: (state, action) => {
      state.currentReachBellStatus = action.payload;
    },
    setCurrentReachBellStatusAnk: (state, action) => {
      state.currentReachBellStatusAnk = action.payload;
    },
    setCurrentReachBellStatusZsu: (state, action) => {
      state.currentReachBellStatusZsu = action.payload;
    },
    setCurrentUserInfo: (state, action) => {
      state.currentUserInfo = action.payload;
    },
    setContractsList: (state, action) => {
      state.contracts = action.payload;
    },
    editContract: (state, action) => {
      const contractIndex = state.contracts.findIndex((i) => i._id === action.payload._id);
      state.contracts[contractIndex] = { ...state.contracts[contractIndex], ...action.payload, block: action.payload.block }; //todo: remove block if it already in payload
    },
    updateContract: (state, action) => {
      // dont like this, its better to use editContract
      state.contracts.find((i) => {
        if (i.i === action.payload.i) {
          for (const key in action.payload.update) {
            i[key] = action.payload.update[key];
          }
          return true;
        }
        return false;
      });
    },
    setModal: (state, action) => {
      state.modal = {
        ...action.payload,
        prev: action.payload?.prev || (state.modal.name ? state.modal : undefined),
      };
    },
    setLawyersList: (state, action) => {
      state.lawyersList = action.payload;
    },
    setAssignmentsList: (state, action) => {
      state.assignmentsList = action.payload;
    },
    setPlacesList: (state, action) => {
      state.placesList = action.payload;
    },
    setJudgesList: (state, action) => {
      state.judgesList = action.payload;
    },
    setClubCardsList: (state, action) => {
      state.clubCardsList = action.payload;
    },
    setFiliiList: (state, action) => {
      state.filii = action.payload;
      state.FILII = action.payload.reduce((acc, item) => {
        acc[item.i] = item.name;
        return acc;
      }, []);
      state.activeFilii = action.payload
        .filter((item) => !item.isDisabled)
        .map((item) => ({ _id: item._id, label: item.name, value: item.i }))
        .sort((a, b) => a.value - b.value);
    },
    setFilialBalance: (state, action) => {
      const { totalBalance, filialIndex } = action.payload;
      const filialIndexInArray = state.filii.findIndex((item) => String(item.i) === String(filialIndex));
      if (filialIndexInArray === -1) {
        return;
      }
      state.filii[filialIndexInArray].totalBalance = totalBalance;
    },
    setFilialTotalBalance: (state, action) => {
      const { totalBalance, filialIndex } = action.payload;
      const filialIndexInArray = state.filii.findIndex((item) => String(item.i) === String(filialIndex));
      if (filialIndexInArray === -1) {
        return;
      }
      state.filii[filialIndexInArray].totalBalance = totalBalance;
    },
    setUserTotalBalance: (state, action) => {
      const { totalBalance, userId } = action.payload;
      state.users[userId].totalBalance = totalBalance;
    },
    setUserTotalPayrolls: (state, action) => {
      const { totalPayrolls, userId } = action.payload;
      state.users[userId].totalPayrolls = totalPayrolls;
    },
    setUserMonthBalance: (state, action) => {
      const { userId, monthBalance, monthPayrolls } = action.payload;
      state.users[userId].monthBalance = monthBalance;
      state.users[userId].monthPayrolls = monthPayrolls;
    },
    deleteFilItem: (state, action) => {
      state.filii = state.filii.filter((item) => item._id !== action.payload);
    },
    addFilItem: (state, action) => {
      state.filii = [action.payload, ...state.filii];
    },
    editFilItem: (state, action) => {
      state.filii = state.filii.map((item) => (item._id === action.payload._id ? action.payload : item));
    },
    setCourtsList: (state, action) => {
      state.courtsList = action.payload;
    },
    setContractsRating: (state, action) => {
      state.contractsRating = action.payload;
    },
    setCurrentMonthContractsRating: (state, action) => {
      state.currentMonthContractsRating = action.payload;
    },
    setReachesRating: (state, action) => {
      state.reachesRating = action.payload;
    },
    setCurrentMonthReachesRating: (state, action) => {
      state.currentMonthReachesRating = action.payload;
    },
    setPravoRating: (state, action) => {
      state.pravoRating = action.payload;
    },
    setHotlineMaxIndex: (state, action) => {
      state.hotlineMaxIndex = action.payload;
    },
    setContractMaxIndex: (state, action) => {
      state.contractMaxIndex = action.payload;
    },
    setSuggestionsAndComplaintsMaxIndex: (state, action) => {
      state.suggestionsAndComplaintsMaxIndex = action.payload;
    },
    setContractArchiveMaxIndex: (state, action) => {
      state.contractArchiveMaxIndex = action.payload;
    },
    setContractQuestionaireMaxIndex: (state, action) => {
      state.contractQuestionaireMaxIndex = action.payload;
    },
    setTotalUncompletedTasks: (state, action) => {
      state.totalUncompletedTasks = action.payload;
    },
    setAutoRotateReachesState: (state, action) => {
      state.autoRotateReachesState = action.payload;
    },
    updateReach: (state, action) => {
      const foundIndex = state.currentReachList.findIndex((reach) => {
        return reach._id === action.payload._id;
      });
      if (foundIndex !== -1) {
        state.currentReachList[foundIndex] = action.payload;
      }
    },
    setFeeList: (state, action) => {
      state.feeList = action.payload;
    },
    addFeeToList: (state, action) => {
      state.feeList = [action.payload, ...state.feeList];
    },
    editFee: (state, action) => {
      state.feeList = state.feeList.map((item) => (item._id === action.payload._id ? { ...item, ...action.payload } : item));
    },
    deleteFee: (state, action) => {
      state.feeList = state.feeList.map((item) => (item._id === action.payload ? { ...item, removed: true } : item));
    },
    setClientFeedbackList: (state, action) => {
      state.clientFeedbackList = action.payload;
    },
    updateClientFeedbackList: (state, action) => {
      state.clientFeedbackList = state.clientFeedbackList.map((item) => {
        return item._id === action.payload._id ? action.payload : item;
      });
    },
    setUnwatchedTendersCount: (state, action) => {
      state.unwatchedTendersCount = action.payload;
    },
    setUnreadedMessagesRedButtonContractsChatWithClient: (state, action) => {
      state.unreadedMessagesRedButtonContractsChatWithClient = action.payload;
    },
    setUnreadedMessages: (state, action) => {
      state.unreadedMessagesTotal = action.payload.total;
      state.unreadedMessagesData = action.payload.data;
    },
    updateUnreadedMessages: (state, action) => {
      const currentUnreaded = state.unreadedMessagesData?.find(
        (item) =>
          item.chatId === action.payload.chatId &&
          (item.chatType === action.payload.chatType ||
            (item.chatType === CHAT_TYPES.redButtonChat.key && action.payload.chatType === CHAT_TYPES.chatWithClient.key)),
      );

      if (currentUnreaded) {
        state.unreadedMessagesData = state.unreadedMessagesData?.filter((item) => item._id !== currentUnreaded._id);
        state.unreadedMessagesTotal -= currentUnreaded?.total;
      }
    },
    addUnreadedMessages: (state, action) => {
      const currentUnreaded = state.unreadedMessagesData?.find(
        (item) => item.chatId === action.payload.chatId && item.chatType === action.payload.chatType,
      );
      state.unreadedMessagesTotal = (state.unreadedMessagesTotal ?? 0) + 1;

      if (!currentUnreaded) {
        state.unreadedMessagesData = [...state.unreadedMessagesData, { chatId: action.payload.chatId, chatType: action.payload.chatType, total: 1 }];
        return;
      }

      state.unreadedMessagesData = state.unreadedMessagesData?.map((item) =>
        item.chatId === currentUnreaded.chatId && item.chatType === action.payload.chatType ? { ...item, total: item.total + 1 } : item,
      );
    },
    setCurrentAuthUserPersonalDate: (state, action) => {
      state.currentAuthUserPersonalDate = action.payload;
    },
    setUserPersonalData: (state, action) => {
      const { userId, userPersonalData } = action.payload;
      return {
        ...state,
        users: {
          ...state.users,
          [userId]: {
            ...state.users[userId],
            userPersonalData: userPersonalData,
          },
        },
      };
    },
    setUserPersonalDataPractice: (state, action) => {
      const { userId, userPersonalData, practice } = action.payload;
      return {
        ...state,
        users: {
          ...state.users,
          [userId]: {
            ...state.users[userId],
            userPersonalData: { ...userPersonalData, practice: [...practice] },
          },
        },
      };
    },
    setActualization: (state, action) => {
      state.actualization = action.payload;
    },
    setActivationInsurance: (state, action) => {
      state.activationInsurance = action.payload;
    },
    setInsurances: (state, action) => {
      state.insurances = action.payload;
    },
    updateInsurance: (state, action) => {
      const { activationInsurance, contractId, dateActivation } = action.payload;
      const insuranceIndex = state.insurances.findIndex((insurance) => insurance._id === activationInsurance);

      if (insuranceIndex !== -1) {
        state.insurances[insuranceIndex] = {
          ...state.insurances[insuranceIndex],
          contractId: contractId,
          dateActivation: dateActivation,
        };
      }
    },

    addInsurance: (state, action) => {
      state.insurances = [...state.insurances, action.payload];
    },
    setWebinars: (state, action) => {
      state.webinars = action.payload;
    },
    addWebinars: (state, action) => {
      state.webinars = [...state.webinars, action.payload];
    },
    editWebinars: (state, action) => {
      state.webinars = state.webinars.map((item) => (item._id === action.payload._id ? { ...item, ...action.payload } : item));
    },
    deleteWebinars: (state, action) => {
      state.webinars = state.webinars.map((item) =>
        item._id === action.payload ? { ...item, status: WEBINAR_STATUSES.FINISHED, disabled: true } : item,
      );
    },
    setTopFilials: (state, action) => {
      state.topFilials = action.payload;
    },
    updateUser: (state, action) => {
      const { _id, ...userData } = action.payload;
      state.users[_id] = { ...state.users[_id], ...userData };
    },
    setArrAddressees: (state, action) => {
      state.arrAddressees = action.payload;
    },
    addArrAddressees: (state, action) => {
      state.arrAddressees = [...new Set([...state.arrAddressees, action.payload])].sort();
    },
    addExpense: (state, action) => {
      const newExpense = Array.isArray(action.payload) ? action.payload : [action.payload];
      state.expenses = [...newExpense, ...state.expenses];
    },
    setExpenses: (state, action) => {
      state.expenses = action.payload;
    },
    editExpense: (state, action) => {
      const { id, updatedData, historyData } = action.payload;

      const expenseIndex = state.expenses.findIndex((item) => item._id === id);

      if (expenseIndex !== -1) {
        const originalExpense = { ...state.expenses[expenseIndex] };

        const editedExpense = {
          ...originalExpense,
          ...updatedData,
        };

        state.expenses[expenseIndex] = editedExpense;

        if (historyData && historyData.amount === '') {
          state.expenses[expenseIndex].history = [...(state.expenses[expenseIndex].history || []), historyData];
        }
      }
    },
    updateExpense: (state, action) => {
      const { id, updatedData } = action.payload;
      const expenseIndex = state.expenses.findIndex((item) => item._id === id);
      if (expenseIndex !== -1) {
        state.expenses[expenseIndex] = { ...state.expenses[expenseIndex], ...updatedData };
      }
    },
    setTasks: (state, action) => {
      state.tasks = action.payload;
    },
    addTask: (state, action) => {
      const { listName = 'ALL', task } = action.payload;

      state.tasks[listName] = [task, ...state.tasks[listName]];
    },
    editTask: (state, action) => {
      const { listName, task } = action.payload;

      state.tasks[listName] = state.tasks[listName]?.map((item) => (item._id === task._id ? { ...item, ...task } : item));
    },
    setDoneTask: (state, action) => {
      const { task } = action.payload;

      if (state.tasks['DONE'].length > 0) {
        if (state.tasks['DONE'].some((item) => item._id === task._id)) {
          state.tasks['DONE'] = state.tasks['DONE'].filter((item) => item._id !== task._id);
          state.tasks['MY'] = [...state.tasks['MY'], task];
        } else {
          state.tasks['DONE'] = [task, ...state.tasks['DONE']];
          state.tasks['MY'] = state.tasks['MY'].filter((item) => item._id !== task._id);
        }
      } else {
        state.tasks['DONE'] = [task];
        state.tasks['MY'] = state.tasks['MY'].filter((item) => item._id !== task._id);
      }
    },
    deleteTask: (state, action) => {
      const { listName, taskId } = action.payload;
      state.tasks[listName] = state.tasks[listName].filter((item) => item._id !== taskId);
    },
    setUnwatchedTasksCount: (state, action) => {
      state.unwatchedTasksCount = action.payload;
    },
    setTasksFilter: (state, action) => {
      state.tasksFilter = action.payload;
    },
    addSugar: (state, action) => {
      const newSugar = Array.isArray(action.payload) ? action.payload : [action.payload];
      state.sugar = [...newSugar, ...state.sugar];
    },
    setSugar: (state, action) => {
      state.sugar = action.payload;
    },
    setPushNotification: (state, action) => {
      state.pushNotification = action.payload;
    },
    setCurrentClientSugar: (state, action) => {
      state.currentClientSugar = action.payload;
    },
    editSugar: (state, action) => {
      const { id, updatedData, historyData } = action.payload;

      const sugarIndex = state.sugar.findIndex((item) => item._id === id);

      if (sugarIndex !== -1) {
        // percent values is not overwrited with originalSugar
        // const originalSugar = { ...state.sugar[sugarIndex] };

        const editedSugar = {
          // ...originalSugar,
          ...updatedData,
        };
        state.sugar[sugarIndex] = editedSugar;

        if (historyData && historyData.amount === '') {
          state.sugar[sugarIndex].history = [...(state.sugar[sugarIndex].history || []), historyData];
        }
      }
    },
    importSugar: (state, action) => {
      state.sugar = [...action.payload, ...state.sugar];
    },
    setSugarApprovedStatus: (state, action) => {
      const { sugarId, approved } = action.payload;
      const findSugar = (item) => item._id === sugarId;
      const sugarIndex = state.sugar.findIndex(findSugar);
      if (sugarIndex !== -1) {
        state.sugar[sugarIndex].approved = approved;
      }
    },
    setExpenseApprovedStatus: (state, action) => {
      const { documentId, approved } = action.payload;
      const findExpense = (item) => item._id === documentId;
      const expenseIndex = state.expenses.findIndex(findExpense);
      if (expenseIndex !== -1) {
        state.expenses[expenseIndex].approved = approved;
      }
    },
    addMfo: (state, action) => {
      state.mfo = [action.payload, ...state.mfo];
    },
    setMfo: (state, action) => {
      state.mfo = action.payload;
    },
    deleteMfoItem: (state, action) => {
      state.mfo = state.mfo.filter((item) => item._id !== action.payload);
    },
    updateMfoItem: (state, action) => {
      state.mfo = state.mfo.map((item) => (item._id === action.payload._id ? action.payload : item));
    },
    setConflicts: (state, action) => {
      state.conflicts = action.payload;
    },
    addNewConflict: (state, action) => {
      state.conflicts = [action.payload, ...state.conflicts];
    },
    editConflict: (state, action) => {
      state.conflicts = state.conflicts.map((item) => (item._id === action.payload._id ? { ...item, ...action.payload } : item));
    },
    addTweet: (state, action) => {
      state.allTweets = [action.payload, ...state.allTweets];
      state.userTweets = [action.payload, ...state.userTweets];
    },
    setAllTweets: (state, action) => {
      state.allTweets = action.payload;
    },
    setUserTweets: (state, action) => {
      state.userTweets = action.payload;
    },
    deleteTweetItem: (state, action) => {
      state.allTweets = state.allTweets.filter((item) => item._id !== action.payload);
      state.userTweets = state.userTweets.filter((item) => item._id !== action.payload);
    },
    updateTweetItem: (state, action) => {
      state.allTweets = state.allTweets.map((item) => (item._id === action.payload._id ? action.payload : item));
      state.userTweets = state.userTweets.map((item) => (item._id === action.payload._id ? action.payload : item));
    },
    toggleFavoriteTweets: (state, action) => {
      const { tweet, isFavorite } = action.payload;
      state.allTweets = state.allTweets.map((t) => (t._id === tweet._id ? { ...t, isFavorite } : t));
      state.userTweets = state.userTweets.map((t) => (t._id === tweet._id ? { ...t, isFavorite } : t));
      state.userFavoriteTweets = state.userFavoriteTweets.map((t) => (t._id === tweet._id ? { ...t, isFavorite } : t));
    },
    setUserFavoriteTweets: (state, action) => {
      state.userFavoriteTweets = action.payload;
    },
    addTwitterComment: (state, action) => {
      const { tweetId, comment } = action.payload;
      const tweetIndex = state.allTweets.findIndex((tweet) => tweet._id === tweetId);

      if (tweetIndex !== -1) {
        const updatedTweet = { ...state.allTweets[tweetIndex] };

        updatedTweet.comments = [...updatedTweet.comments, comment];

        state.allTweets[tweetIndex] = updatedTweet;
      }
    },
    setQuickChats: (state, action) => {
      state.quickChats = action.payload;
    },
    setChatsData: (state, action) => {
      state.chatsData = { ...{}, [action.payload?.type]: [...action.payload.data] };
    },
    updateChat: (state, action) => {
      const { chatType, data } = action.payload;

      const currentChat = state.chatsData?.[chatType]?.find((item) => item._id === data._id && item.type === chatType);

      if (!currentChat?._id) {
        state.chatsData = { ...state.chatsData, [chatType]: [data, ...state.chatsData[chatType]] };
      } else {
        state.chatsData = {
          ...state.chatsData,
          [chatType]: state.chatsData?.[chatType]?.map((item) => (item._id === data._id ? { ...currentChat, ...data } : item)),
        };
      }
    },
    addNewMessageToChat: (state, action) => {
      const { chatType, data, chatId } = action.payload;

      if (chatType === CHAT_TYPES.redButtonChat.key) {
        const clientChatIndex = state.chatsData?.[CHAT_TYPES.chatWithClient.key]?.findIndex((item) => item._id === chatId);
        if (clientChatIndex >= 0) {
          state.chatsData = {
            ...state.chatsData,
            [CHAT_TYPES.chatWithClient.key]: state.chatsData[CHAT_TYPES.chatWithClient.key]
              .map((item) => (item._id === chatId ? { ...item, messages: [...item.messages, data] } : item))
              .sort((itemA, itemB) => {
                if (state.unreadedMessagesData.findIndex((item) => item.chatId === itemA._id) >= 0) return -2;
                else {
                  return (
                    new Date(itemA?.messages?.[itemA?.messages?.length - 1]?.date) < new Date(itemB?.messages?.[itemB?.messages?.length - 1]?.date)
                  );
                }
              }),
          };
        }
      }
      if (chatType === CHAT_TYPES.chatWithClient.key) {
        const redButtonChatIndex = state.chatsData?.[CHAT_TYPES.redButtonChat.key]?.findIndex((item) => item._id === chatId);
        if (redButtonChatIndex >= 0) {
          state.chatsData = {
            ...state.chatsData,
            [CHAT_TYPES.redButtonChat.key]: state.chatsData[CHAT_TYPES.redButtonChat.key]
              .map((item) => (item._id === chatId ? { ...item, messages: [...item.messages, data] } : item))
              .sort((itemA, itemB) => {
                if (state.unreadedMessagesData.findIndex((item) => item.chatId === itemA._id) >= 0) return -2;
                else {
                  return (
                    new Date(itemA?.messages?.[itemA?.messages?.length - 1]?.date) < new Date(itemB?.messages?.[itemB?.messages?.length - 1]?.date)
                  );
                }
              }),
          };
        }
      }

      const currentChatIndex = state.chatsData?.[chatType]?.findIndex((item) => item._id === chatId);

      if (currentChatIndex >= 0) {
        state.chatsData = {
          ...state.chatsData,
          [chatType]: state.chatsData[chatType]
            .map((item) => (item._id === chatId ? { ...item, messages: [...item.messages, data] } : item))
            .sort((itemA, itemB) => {
              if (state.unreadedMessagesData.findIndex((item) => item.chatId === itemA._id) >= 0) return -2;
              else {
                return (
                  new Date(itemA?.messages?.[itemA?.messages?.length - 1]?.date) < new Date(itemB?.messages?.[itemB?.messages?.length - 1]?.date)
                );
              }
            }),
        };
      }
    },
    removeChat: (state, action) => {
      const { chatType, chatId } = action.payload;

      state.chatsData = { ...state.chatsData, [chatType]: state.chatsData?.[chatType]?.filter((item) => item._id !== chatId) };
    },
    removeMessage: (state, action) => {
      const { chatType, chatId, messageId } = action.payload;

      state.chatsData = {
        ...state.chatsData,
        [chatType]: state.chatsData?.[chatType]?.map((chat) =>
          chat._id === chatId
            ? { ...chat, messages: chat?.messages?.map((message) => (message._id === messageId ? { ...message, removed: true } : message)) }
            : chat,
        ),
      };
    },
    editMessage: (state, action) => {
      const { chatType, chatId, messageId, newMessageText } = action.payload;

      state.chatsData = {
        ...state.chatsData,
        [chatType]: state.chatsData?.[chatType]?.map((chat) =>
          chat._id === chatId
            ? {
                ...chat,
                messages: chat?.messages?.map((message) =>
                  message._id === messageId ? { ...message, message: newMessageText, edited: true } : message,
                ),
              }
            : chat,
        ),
      };
    },
    addPinnedMessage: (state, action) => {
      const { chatId, chatType, pinnedMessages } = action.payload;

      state.chatsData = {
        ...state.chatsData,
        [chatType]: state.chatsData?.[chatType]?.map((chat) => (chat._id === chatId ? { ...chat, pinnedMessages: pinnedMessages } : chat)),
      };
    },
    clearPinnedMessages: (state, action) => {
      const { chatId, chatType } = action.payload;

      state.chatsData = {
        ...state.chatsData,
        [chatType]: state.chatsData?.[chatType]?.map((chat) => (chat._id === chatId ? { ...chat, pinnedMessages: [] } : chat)),
      };
    },
    setSocket: (state, action) => {
      state.socket = action.payload;
    },
    setOnlineUsers: (state, action) => {
      state.onlineUsers = action.payload;
    },
    setTemplates: (state, action) => {
      state.templates = action.payload;
    },
    setContractsWithoutBlockCount: (state, action) => {
      state.contractsWithoutBlockCount = action.payload;
    },
    setAllInsuranceTypes: (state, action) => {
      state.allInsuranceTypes = action.payload;
    },
    addInsuranceTypes: (state, action) => {
      state.insuranceTypes = [...state.insuranceTypes, action.payload];
      state.allInsuranceTypes = [...state.allInsuranceTypes, action.payload];
    },
    setInsuranceTypes: (state, action) => {
      state.insuranceTypes = [...action.payload];
    },
    editInsuranceTypes: (state, action) => {
      state.insuranceTypes = state.insuranceTypes.map((item) => (item._id === action.payload._id ? { ...item, ...action.payload } : item));
      state.allInsuranceTypes = state.allInsuranceTypes.map((item) => (item._id === action.payload._id ? { ...item, ...action.payload } : item));
    },
    deleteInsuranceType: (state, action) => {
      state.insuranceTypes = state.insuranceTypes.filter((item) => item._id !== action.payload);
      state.allInsuranceTypes = state.allInsuranceTypes.filter((item) => item._id !== action.payload);
    },
    setFilialWorkers: (state, action) => {
      state.filialWorkers = action.payload;
    },
    setSelectedFilial: (state, action) => {
      state.selectedFilial = action.payload;
    },
    setDublicates: (state, action) => {
      const { key, total, data, page } = action.payload;
      state.dublicates = {
        ...state.dublicates,
        [key]: {
          total: total,
          data: {
            ...state.dublicates[key]?.data,
            [page]: data,
          },
        },
      };
    },
    setContractsChatsFilter: (state, action) => {
      const { type, key, val } = action.payload;

      state.contractsChatsFilter = {
        ...state.contractsChatsFilter,
        [type]: {
          ...state.contractsChatsFilter[type],
          [key]: val,
        },
      };
    },
    clearContractsChatsFilter: (state, action) => {
      const { type } = action.payload;

      state.contractsChatsFilter = {
        ...state.contractsChatsFilter,
        [type]: {
          fil: '',
        },
      };
    },
    setHotlineCalls: (state, action) => {
      state.hotlineCalls = action.payload;
    },
    updateHotlineCall: (state, action) => {
      state.hotlineCalls = state.hotlineCalls.map((hotlineCall) =>
        hotlineCall._id === action.payload._id ? { ...hotlineCall, ...action.payload } : hotlineCall,
      );
    },
    updateHotlineCallsFilter: (state, action) => {
      const { key, val } = action.payload;
      state.hotlineCallsFilter = { ...state.hotlineCallsFilter, [key]: val };
    },
    clearHotlineCallsFilter: (state, action) => {
      state.hotlineCallsFilter = {};
    },
  },
});

export const {
  setUserAuth,
  setAppPrepared,
  setUsers,
  setModal,
  setCurrentClient,
  setCurrentHotlineList,
  setCurrentSearchList,
  setCurrentHackworksList,
  setHarvestList,
  editHarvest,
  setClientsList,
  editClient,
  setCurrentReachList,
  setCurrentReachAnkList,
  setCurrentReachZsuList,
  setCurrentReachBellStatus,
  setCurrentReachBellStatusAnk,
  setCurrentReachBellStatusZsu,
  setCurrentUserInfo,
  setContractsList,
  setPartnersList,
  setPartnerBalance,
  editContract,
  editHotline,
  editUser,
  updateContract,
  setLawyersList,
  setAssignmentsList,
  setJudgesList,
  setPlacesList,
  setCourtsList,
  setFiliiList,
  deleteFilItem,
  addFilItem,
  editFilItem,
  setLibraryList,
  setClubCardsList,
  deleteLibraryItem,
  addLibraryItem,
  setLibraryMiniForModal,
  setBankCardsList,
  editBankCardsItem,
  addBankCardsItem,
  setCorrespondenceListModalForTheContract,
  addCorrespondenceItemModalForTheContract,
  editCorrespondenceItemModalForTheContract,
  deleteCorrespondenceContractModalForItem,
  setCorrespondenceListPage,
  addCorrespondenceItemPage,
  editCorrespondenceItemPage,
  deleteCorrespondencePageForItem,
  setCurrentCorrespondence,
  setContractsRating,
  setCurrentMonthContractsRating,
  setReachesRating,
  setCurrentMonthReachesRating,
  setAutoRotateReachesState,
  setPravoRating,
  updateReach,
  setFeeList,
  addFeeToList,
  editFee,
  deleteFee,
  setHotlineMaxIndex,
  setContractMaxIndex,
  setSuggestionsAndComplaintsMaxIndex,
  setContractArchiveMaxIndex,
  setContractQuestionaireMaxIndex,
  setTotalUncompletedTasks,
  setClientFeedbackList,
  updateClientFeedbackList,
  setUnreadedMessages,
  updateUnreadedMessages,
  addUnreadedMessages,
  setCurrentAuthUserPersonalDate,
  setUserPersonalData,
  setUserPersonalDataPractice,
  setActualization,
  setActivationInsurance,
  setInsurances,
  addInsurance,
  setWebinars,
  addWebinars,
  editWebinars,
  deleteWebinars,
  updateInsurance,
  setArrAddressees,
  addArrAddressees,
  setTopFilials,
  updateUser,
  addExpense,
  setExpenses,
  editExpense,
  updateExpense,
  setExpenseApprovedStatus,
  setTasks,
  addTask,
  editTask,
  setDoneTask,
  deleteTask,
  setUnwatchedTasksCount,
  setTasksFilter,
  addSugar,
  setSugar,
  setCurrentClientSugar,
  editSugar,
  setSugarApprovedStatus,
  importSugar,
  setUnwatchedTendersCount,
  setPushNotification,
  addMfo,
  setMfo,
  deleteMfoItem,
  updateMfoItem,
  setConflicts,
  addNewConflict,
  editConflict,
  addTweet,
  setAllTweets,
  setUserTweets,
  deleteTweetItem,
  updateTweetItem,
  toggleFavoriteTweets,
  setUserFavoriteTweets,
  addTwitterComment,
  setUnreadedMessagesRedButtonContractsChatWithClient,
  setQuickChats,
  setFilialBalance,
  setFilialTotalBalance,
  setUserTotalBalance,
  setUserTotalPayrolls,
  setUserMonthBalance,
  setChatsData,
  updateChat,
  addNewMessageToChat,
  removeChat,
  removeMessage,
  editMessage,
  addPinnedMessage,
  clearPinnedMessages,
  setSocket,
  setOnlineUsers,
  setAnkTemplates,
  setTemplates,
  setContractsWithoutBlockCount,
  setAllInsuranceTypes,
  setInsuranceTypes,
  addInsuranceTypes,
  editInsuranceTypes,
  deleteInsuranceType,
  setFilialWorkers,
  setDublicates,
  setContractsChatsFilter,
  clearContractsChatsFilter,
  setHotlineCalls,
  updateHotlineCall,
  updateHotlineCallsFilter,
  clearHotlineCallsFilter,
  editReachItem,
  editReachItemAnk,
  editReachItemZsu,
  setCurrentPsychologistHotline,
  setCurrentPsychologistHotlineCalendar,
} = commonSlice.actions;
export const selectUserAuth = (state) => state.common.userAuth;
export const selectUsers = (state) => state.common.users;
export default commonSlice.reducer;
